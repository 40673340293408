<template>
  <div class="tw-fixed tw-bottom-4 tw-right-4 lg:tw-hidden tw-transition-all tw-will-change-auto tw-z-40"
       :class="{ 'tw-opacity-1 tw-translate-y-0': $store.getters.sticky, 'tw-opacity-0 tw- tw-pointer-events-none tw-translate-y-4': !$store.getters.sticky }">
    <v-btn @click="scrollToTop"
           class="tw-h-10 tw-w-10 tw-bg-ci-green"
           large
           icon>
      <v-icon class="tw-text-white">mdi-chevron-up
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ScrollToTop',
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
