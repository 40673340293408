<script>
import demoDataNodesQuery from '@/gql/queries/demoDataNodes.graphql'
import { defineComponent, ref } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'ResetSimulationDatabaseDialog',
  setup() {
    const form = ref({
      referencedDemoDataNode: null,
    })
    const loading = ref(false)
    return {
      form,
      loading,
    }
  },
  apollo: {
    demoDataNodes: {
      query: demoDataNodesQuery,
    },
  },
  computed: {
    ...mapGetters(['selectedNode']),
    availableDemoDataNodes() {
      return this.demoDataNodes.map((node) => ({
        text: node.name,
        value: node._id,
      }))
    },
  },
  data: () => ({
    demoDataNodes: [],
  }),
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    async resetSimulationDatabase() {
      await this.$store.dispatch('pauseNotificationSubscription')
      this.loading = true
      try {
        await window.fetch(
          `${config.VUE_APP_API_ENDPOINT}/api/simulation/resetSimDb`,
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              node: this.selectedNode,
              referencedDemoDataNode: this.form.referencedDemoDataNode,
            }),
          },
        )
        this.loading = false
        this.closeDialog()
        if (this.$route.name !== 'invoices') {
          try {
            await this.$router.push({
              name: 'invoices',
              query: {
                node: this.selectedNode,
              },
              params: { state: 'open' },
            })
          } catch (e) {
            // DO NOTHING
          }
        }
        await this.$store.dispatch('resumeNotificationSubscription')
        await this.$apolloProvider.defaultClient.resetStore()
      } catch (e) {
        this.loading = false
        alert(e)
      }
    },
  },
})
</script>

<template>
  <DialogContainer>
    <DialogHeader :hideCloseBtn="loading" @close="closeDialog">
      Datenbank zurücksetzen
    </DialogHeader>
    <DialogBody class="tw-pt-8 tw-pb-3 tw-px-8">
      <p class="tw-m-0">
        Die Simulationsdatenbank wird zunächst gelöscht und mit neuen
        simulierten Daten initialisiert. Es handelt sich dabei nicht um echte
        Daten, sondern lediglich um generierte Daten.
      </p>
      <p class="tw-mt-6 tw-mb-0">
        Optional kann die Datenbank mit einer ausgewählten Datenquelle
        initialisiert werden. Wenn keine Datenquelle ausgewählt wird, werden
        Lorem-Ipsum-Daten verwendet.
      </p>
      <InputSelect
        v-model="form.referencedDemoDataNode"
        :disabled="loading"
        :items="availableDemoDataNodes"
        class="tw-mt-4"
        label="Datenquelle" />
      <InfoText color="green">
        Das Zurücksetzen der Datenbank kann einige Minuten dauern. Bitte warten
        Sie, bis der Vorgang abgeschlossen ist.
      </InfoText>
    </DialogBody>
    <DialogFooter>
      <DialogActions>
        <PrimaryButton
          :loading="loading"
          @click.prevent="resetSimulationDatabase">
          Datenbank zurücksetzen
        </PrimaryButton>
      </DialogActions>
    </DialogFooter>
  </DialogContainer>
</template>
