<template>
  <div>
    <Bar :chart-data="barchartdata" />
  </div>
</template>

<script>
import Bar from '@/components/helper/Bar'

export default {
  components: {
    Bar,
  },
  props: ['data'],
  computed: {
    barchartdata: function () {
      return {
        labels: this.data.map((d) => d.month),
        datasets: [
          {
            data: this.data.map((d) => d.value),
            backgroundColor: '#82C8E2',
            borderWidth: 0,
            borderRadius: 5,
            borderSkipped: false,
            barPercentage: 0.5,
          },
        ],
      }
    },
  },
}
</script>
