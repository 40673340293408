<template>
  <div class="tw-bg-white dialog-container tw-rounded tw-grid tw-overflow-hidden tw-max-h-[90vh-4px)] tw-transition-all tw-duration-300 tw-shadow-2xl">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DialogContainer',
}
</script>

<style lang="scss">
.dialog-container {
  grid-template-rows: 0fr auto 0fr;

  > *:nth-child(2) {
    overflow: auto;
  }
}
</style>
