<template>
    <div id="blank-wrapper">
        <router-view></router-view>
    </div>
</template>
<script>
  export default {
    mounted() {
      window.iFrameResizer = {
        targetOrigin: 'https://www.sanadeus.de'
      }
      let element = document.getElementById('blank-wrapper')
      let iframeResizer = document.createElement('script')
      iframeResizer.src = '/js/iframeResizer.contentWindow.js'
      iframeResizer.async = true
      element.appendChild(iframeResizer)
    }
  }
</script>
