var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.availableNodes.length > 1)?_c('div',{staticClass:"node-selector tw-absolute tw-top-2"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',{staticClass:"selector tw-flex tw-flex-row tw-items-center lg:tw-mr-3"},[_c('v-btn',_vm._g({staticClass:"tw-pl-4 tw-pr-3",attrs:{"color":"green","outlined":""}},on),[_c('span',{staticClass:"button-text tw-hidden sm:tw-block"},[_vm._v(_vm._s(_vm.currentNode.name))]),_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-chevron-down")])],1)],1)]}}],null,false,3235362733)},[_c('div',{staticClass:"tw-bg-white tw-w-64"},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.availableNodes),function(node){return _c('v-list-item',{key:node._id,staticClass:"node",class:{ active: _vm.currentNode.id === node.id }},[_c('v-list-item-title',{staticClass:"tw-text-base tw-text-ci-gray-600",on:{"click":function($event){return _vm.setSelectedNode(node)}}},[_vm._v(" "+_vm._s(node.name)+" ")])],1)}),1)],1)])],1):_vm._e(),_c('div',{staticClass:"tw-absolute tw-top-2 tw-right-2"},[_c('v-btn',{staticClass:"tw-h-10 tw-w-10 lg:tw-hidden",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return (() => _vm.$store.dispatch('hideMenu')).apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('ul',{staticClass:"snds-nav-list"},_vm._l((_vm.envNavItems),function(item){return _c('li',{key:item.name},[(item.type === 'divider')?_c('hr',{staticClass:"tw-mx-6 tw-my-4"}):_vm._e(),(item.type === 'link')?_c('router-link',{class:[
          {
            'router-link-exact-active':
              _vm.$route.name === item.link ||
              (_vm.$route.name && _vm.$route.name.includes(item?.parent)),
          },
          item.icon,
        ],attrs:{"to":{ name: item.link, params: item.params, query: { node: _vm.selectedNode } }}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }