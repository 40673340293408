const state = {
  addMaterialCost: false,
  editMaterialCost: null,
  deleteMaterialCost: null,
}

const getters = {
  isAddMaterialCostDialogVisible: (state) => {
    return !!state.addMaterialCost
  },
  isEditMaterialCostDialogVisible: (state) => {
    return !!state.editMaterialCost
  },
  materialCostToEdit: (state) => {
    return state.editMaterialCost
  },
  isDeleteMaterialCostDialogVisible: (state) => {
    return !!state.deleteMaterialCost
  },
  materialCostToDelete: (state) => {
    return state.deleteMaterialCost
  },
}

const mutations = {
  setAddMaterialCost(state, payload) {
    state.addMaterialCost = payload
  },
  setEditMaterialCost(state, payload) {
    state.editMaterialCost = payload
  },
  setDeleteMaterialCost(state, payload) {
    state.deleteMaterialCost = payload
  },
}

const actions = {
  showAddMaterialCostDialog({ commit }) {
    commit('setAddMaterialCost', true)
  },
  closeAddMaterialCostDialog({ commit }) {
    commit('setAddMaterialCost', false)
  },
  showEditMaterialCostDialog({ commit }, materialCost) {
    commit('setEditMaterialCost', materialCost)
  },
  closeEditMaterialCostDialog({ commit }) {
    commit('setEditMaterialCost', null)
  },
  showDeleteMaterialCostDialog({ commit }, materialCost) {
    commit('setDeleteMaterialCost', materialCost)
  },
  closeDeleteMaterialCostDialog({ commit }) {
    commit('setDeleteMaterialCost', null)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
