<template>
  <v-dialog
    v-model="dialog"
    content-class="tw-max-w-2xl"
    v-bind="$attrs"
    v-on="$listeners">
    <DialogContainer>
      <DialogHeader @close="cancel">
        {{ companyNotification.title }}
      </DialogHeader>
      <DialogBody class="tw-px-4 lg:tw-px-8 tw-pt-7 tw-pb-5">
        <div class="content" v-html="companyNotification.content"></div>
      </DialogBody>
      <DialogFooter>
        <DialogActions>
          <PrimaryButton
            v-if="companyNotification.showReloadAction"
            @click="reload">
            Aktualisieren
          </PrimaryButton>
        </DialogActions>
      </DialogFooter>
    </DialogContainer>
  </v-dialog>
</template>

<script>

export default {
  name: 'CompanyNotifcationDialog',
  data() {
    return {
      companyNotification: {},
      dialog: false,
    }
  },
  apollo: {
    companyNotification: {
      query: require('@/gql/companyNotification.graphql'),
      update(data) {
        if (!data.companyNotification) {
          return {}
        }
        const { _id } = data.companyNotification
        this.dialog = true
        this.$apolloProvider.defaultClient.mutate({
          mutation: require('@/gql/mutations/markCompanyNotificationAsSeen.graphql'),
          variables: {
            companyNotificationId: _id,
          },
        })
        return data.companyNotification
      },
    },
    $subscribe: {
      newCompanyNotification: {
        query: require('@/gql/subscriptions/newCompanyNotification.graphql'),
        result({ data }) {
          const { newCompanyNotification } = data
          this.companyNotification = newCompanyNotification
          this.$apolloProvider.defaultClient.mutate({
            mutation: require('@/gql/mutations/markCompanyNotificationAsSeen.graphql'),
            variables: {
              companyNotificationId: newCompanyNotification._id,
            },
          })
          this.dialog = true
        },
      },
    },
  },
  methods: {
    cancel() {
      this.dialog = false
      this.$emit('cancel')
    },
    reload() {
      window.location.reload()
    },
  },
}
</script>

<style>
.content > * + * {
  @apply tw-mt-4;
}

.content h1,
.content h2,
.content h3 {
  @apply tw-font-semibold;
}

.content h1 {
  @apply tw-text-2xl;
}

.content h2 {
  @apply tw-text-xl;
}

.content h3 {
  @apply tw-text-lg;
}

.content ul {
  @apply tw-list-disc;
}

.content ol {
  @apply tw-list-decimal;
}

.content p {
  @apply tw-mb-0;
}
</style>
