<template>
  <div class="tw-grid lg:tw-grid-cols-doughnut tw-gap-8">
    <div class="tw-relative">
      <div
        class="doughnut_counter tw-h-full tw-w-full lg:tw-h-44 lg:tw-w-44 tw-flex tw-justify-center tw-items-center tw-absolute tw-text-center tw-font-bold tw-text-6xl lg:tw-text-5xl tw-z-0 tw-pointer-events-none">
        {{ counter }}
      </div>
      <Doughnut
        :chart-data="doughnutchartdata"
        class="tw-w-full lg:tw-h-44 lg:tw-w-44 tw-relative tw-z-1" />
    </div>
    <div class="doughnut_legendbox">
      <div class="tw-font-bold tw-text-[.9375rem] tw-text-ci-gray-400">
        Offene Posten
      </div>
      <div class="legend_amount">
        <Amount :value="totalopen" class="tw-font-extrabold" />
      </div>
      <div class="legend_row tw-mt-1">
        <div class="legend_color bgcolor_open" />
        <div class="legend_amount">
          <Amount :value="data.open" class="tw-font-extrabold" />
        </div>
        <div class="tw-text-sm tw-text-ci-gray-400">nicht fällig</div>
      </div>
      <div class="legend_row">
        <div class="legend_color bgcolor_due" />
        <div class="legend_amount">
          <Amount :value="data.due" class="tw-font-extrabold" />
        </div>
        <div class="tw-text-sm tw-text-ci-gray-400">fällig</div>
      </div>
      <div class="legend_row">
        <div class="legend_color bgcolor_overdue" />
        <div class="legend_amount">
          <Amount :value="data.overdue" class="tw-font-extrabold" />
        </div>
        <div class="tw-text-sm tw-text-ci-gray-400">überfällig</div>
      </div>
      <div class="tw-font-bold tw-text-[.9375rem] tw-text-ci-gray-400">
        Bezahlt
      </div>
      <div class="legend_row">
        <div class="legend_color bgcolor_paid" />
        <div class="legend_amount">
          <Amount :value="data.paid" class="tw-font-extrabold" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Doughnut from '@/components/helper/Doughnut'

export default {
  components: {
    Doughnut,
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {}
      },
    },
    counter: Number,
  },
  computed: {
    totalopen: function () {
      return [this.data.open, this.data.due, this.data.overdue].reduce(
        (sum, cur) => sum + cur,
        0,
      )
    },
    doughnutchartdata: function () {
      if (this.counter === 0 || !this.data) {
        return {
          labels: ['keine Rechnung vorhanden'],
          datasets: [
            {
              data: [1],
              backgroundColor: ['#EDEDED'],
              borderColor: '#FFFFFF',
              borderWidth: 2,
            },
          ],
        }
      }
      return {
        labels: ['nicht fällig', 'fällig', 'überfällig', 'bezahlt'],
        datasets: [
          {
            data: [
              this.data.open,
              this.data.due,
              this.data.overdue,
              this.data.paid,
            ],
            backgroundColor: ['#82C8E2', '#EF8305', '#F82C06', '#00A82D'],
            borderColor: '#FFFFFF',
            borderWidth: 2,
          },
        ],
      }
    },
  },
}
</script>

<style>
.legend_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.legend_color {
  height: 20px;
  margin-right: 15px;
  width: 20px;
}

.legend_amount {
  font-weight: bold;
  margin-right: 15px;
  width: 80px;
}

.legend_row .legend_amount {
  text-align: right;
}

.bgcolor_open {
  background-color: #82c8e2;
}

.bgcolor_due {
  background-color: #ef8305;
}

.bgcolor_overdue {
  background-color: #f82c06;
}

.bgcolor_paid {
  background-color: #00a82d;
}

.doughnut_counter {
  color: #1f78b4;
}
</style>
