<template>
  <td>
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: 'DataTableColumn',
}
</script>

<style>
.snds-data-table--with-actions td:nth-last-child(2) {
  padding-right: 0 !important;
}
</style>
