<template>
  <div
    class="tw-border-dashed tw-border-b-2 tw-border-b-ci-gray-150 tw-pb-5 lg:tw-pb-0 lg:tw-border-b-0">
    <div class="lg:tw-h-24 lg:tw-flex lg:tw-flex-col lg:tw-mt-4">
      <div
        class="tw-flex tw-justify-between tw-flex-col lg:tw-flex-row tw-px-4 lg:tw-px-12 tw-items-between tw-mt-4 lg:tw-mt-0 tw-h-full">
        <h1 class="tw-text-2xl lg:tw-text-5xl tw-font-bold tw-text-ci-gray-700">
          <slot name="title"></slot>
        </h1>
        <div class="tw-flex tw-flex-col tw-justify-between">
          <div>
            <slot name="filter"></slot>
          </div>
          <div>
            <slot name="search"></slot>
          </div>
        </div>
      </div>
    </div>
    <slot name="tabs">
      <div v-if="tabs">
        <div class="lg:tw-hidden tw-px-4 lg:tw-px-12 tw-mt-5">
          <v-select
            v-model="selectedTab"
            :items="showableTabs"
            class="tw-h-10 tw-w-full"
            color="#00A82D"
            dense
            item-text="name"
            outlined
            return-object
            @change="$router.push($event.to)" />
        </div>
        <div
          class="tw-border-b tw-border-b-ci-gray-150 tw-px-4 lg:tw-px-12 tw-w-full lg:-tw-mt-3 tw-hidden lg:tw-flex">
          <v-tabs
            color="#919090"
            height="38"
            slider-color="#00A82D"
            slider-size="2">
            <v-tab
              v-for="(tab, i) in showableTabs"
              :key="i"
              :disabled="tab.disabled"
              :ripple="false"
              :to="tab.to"
              class="before:tw-hidden hover:tw-text-ci-gray-700 tw-transition-colors">
              {{ tab.name }}
            </v-tab>
          </v-tabs>
        </div>
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  props: {
    tabs: { type: Array },
  },
  data() {
    return {
      selectedTab: this.tabs
        ? this.tabs.find((tab) => {
            try {
              const stateSelector = Object.keys(tab.to.params)[0]
              return (
                this.$route.params[stateSelector] ===
                tab.to.params[stateSelector]
              )
            } catch (e) {
              return tab.to.name === this.$route.name
            }
          })
        : null,
    }
  },
  watch: {
    $route: function (val) {
      this.selectedTab = this.tabs.find((tab) => {
        try {
          const stateSelector = Object.keys(tab.to.params)[0]
          return val.params[stateSelector] === tab.to.params[stateSelector]
        } catch (e) {
          return tab.to.name === val.name
        }
      })
    },
  },
  computed: {
    showableTabs() {
      return this.tabs.filter((tab) => {
        return tab.condition !== false
      })
    },
  },
}
</script>

<style>
.theme--light.v-tabs > .v-tabs-bar {
  @apply tw-bg-transparent;
}

.v-tabs {
  @apply tw-flex-grow-0 tw-w-auto;
}

.v-tab {
  @apply tw-text-base tw-font-semibold tw-px-0 tw-min-w-0 tw-text-ci-gray-400;
  text-transform: none;
}

.v-tabs-bar__content a + a {
  @apply tw-ml-8;
}

.v-tab.v-tab--active {
  @apply tw-text-ci-gray-700;
}
</style>

<style scoped>
.customer-info {
  display: flex;
  flex: 1 1 auto;
  font-weight: bold;
  justify-content: flex-end;
}
</style>
