import {
  ApolloErrorHandler,
  GraphQLErrorHandler,
  NetworkErrorHandler,
} from '@/error-handler'
import cache from '@/vue-cache'
import { ApolloClient } from '@apollo/client/core'
import { split } from '@apollo/client/link/core'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'
import { createClient } from 'graphql-ws'
import Vue from 'vue'
import VueApollo from 'vue-apollo'

Vue.use(VueApollo)

const httpLink = createUploadLink({
  uri: config.VUE_APP_GRAPHQL_ENDPOINT,
  headers: { 'Apollo-Require-Preflight': 'true' },
  credentials: 'include',
})
const wsLink = new GraphQLWsLink(
  createClient({
    url: config.VUE_APP_GRAPHQL_WS,
  }),
)
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    GraphQLErrorHandler(graphQLErrors)
  }
  if (networkError) {
    NetworkErrorHandler(networkError)
  }
})
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  errorLink.concat(httpLink),
)

// Config
const defaultOptions = {
  wsEndpoint: config.VUE_APP_GRAPHQL_WS,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  httpLinkOptions: {
    credentials: 'include',
  },
  link,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only',
    },
  },
}

export const apolloClient = new ApolloClient({
  ...defaultOptions,
})

// Call this in the Vue app file
export function createProvider() {
  return new VueApollo({
    defaultClient: apolloClient,
    errorHandler(err) {
      ApolloErrorHandler(err)
    },
  })
}
