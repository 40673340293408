<template>
  <header
    class="tw-px-4 lg:tw-px-8 tw-pt-4 tw-pb-3 tw-border-b-2 tw-border-b-gray-200 tw-relative tw-flex tw-justify-between">
    <h2 class="tw-text-2xl tw-font-bold">
      <slot></slot>
    </h2>
    <slot name="actions">
      <v-btn
        v-if="!hideCloseBtn"
        class="tw-text-ci-gray-500 tw-translate-x-5 -tw-my-1"
        icon
        @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </slot>
  </header>
</template>

<script>
export default {
  name: 'DialogHeader',
  props: {
    hideCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>
