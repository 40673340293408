<script>
import UploadPadFileDialog from '@/components/Simulation/UploadPadFileDialog.vue'
import { defineComponent, ref } from 'vue'
import { mapGetters } from 'vuex'
import ResetSimulationDatabaseDialog from './ResetSimulationDatabaseDialog.vue'

export default defineComponent({
  name: 'SimulationBanner',
  components: {
    UploadPadFileDialog,
    ResetSimulationDatabaseDialog,
  },
  setup() {
    const showBanner = ref(false)
    const resetSimulationDatabaseDialog = ref(false)
    const uploadPadFileDialog = ref(false)
    return {
      showBanner,
      resetSimulationDatabaseDialog,
      uploadPadFileDialog,
    }
  },
  computed: {
    ...mapGetters(['roles']),
  },
  methods: {
    showResetSimulationDatabaseDialog() {
      this.showBanner = false
      this.resetSimulationDatabaseDialog = true
    },
    showUploadPadFileDialog() {
      this.showBanner = false
      this.uploadPadFileDialog = true
    },
  },
})
</script>

<style lang="css" scoped>
.v-btn {
  @apply tw-justify-start;
}

.v-btn >>> .v-btn__content {
  @apply tw-flex-none;
}
</style>

<template>
  <div
    v-if="roles.board_simulation"
    v-click-outside="() => (showBanner = false)"
    class="tw-absolute tw-left-3 tw-bottom-0 tw-w-64">
    <div
      :class="{
        'tw-mb-8': showBanner,
        '-tw-mb-[100%] tw-pointer-events-none': !showBanner,
      }"
      class="tw-rounded-t-md tw-bg-white tw-shadow-lg tw-px-4 tw-pt-4 tw-pb-6 tw-relative tw-transition-all tw-duration-200">
      <p class="tw-m-0 tw-text-sm">
        Hier können Sie Aktionen durchführen, um den Echtbetrieb der Software zu
        simulieren.
      </p>
      <div class="tw-space-y-4 tw-mt-5">
        <v-btn
          block
          class="tw-whitespace-nowrap tw-text-left tw-text-white"
          color="grey"
          depressed
          elevation="0"
          small
          @click.prevent="showResetSimulationDatabaseDialog()">
          Zurücksetzen der Daten
        </v-btn>
        <v-btn
          block
          class="tw-whitespace-nowrap tw-text-left tw-text-white"
          color="grey"
          depressed
          elevation="0"
          small
          @click.prevent="showUploadPadFileDialog()">
          Upload einer PAD-Datei
        </v-btn>
      </div>
    </div>
    <button
      :class="{
        'tw-rounded-t-md': !showBanner,
      }"
      class="tw-w-full hover:tw-bg-ci-green/90 tw-absolute tw-bottom-0 tw-transition-transform tw-duration-300 tw-h-8 tw-bg-ci-green tw-text-white tw-flex tw-items-center tw-justify-center tw-space-x-4"
      @click.prevent="showBanner = !showBanner">
      <span>Demo - Simulation</span>
      <v-icon
        :class="{
          'tw-rotate-180': showBanner,
        }"
        class="tw-text-current">
        mdi-chevron-up
      </v-icon>
    </button>
    <v-dialog
      v-model="resetSimulationDatabaseDialog"
      content-class="tw-max-w-xl"
      persistent>
      <ResetSimulationDatabaseDialog
        v-if="resetSimulationDatabaseDialog"
        @close="resetSimulationDatabaseDialog = false" />
    </v-dialog>
    <v-dialog
      v-model="uploadPadFileDialog"
      content-class="tw-max-w-xl"
      persistent>
      <UploadPadFileDialog
        v-if="uploadPadFileDialog"
        @close="uploadPadFileDialog = false" />
    </v-dialog>
  </div>
</template>
