var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',{staticClass:"tw-w-full tw-block",style:({ height: `${_vm.stroke}px` })},[(_vm.loading)?[_c('line',{style:({
        strokeWidth: _vm.stroke,
        stroke: 'rgba(0, 0, 0, 0.12)',
      }),attrs:{"y1":_vm.y,"y2":_vm.y,"x1":"0","x2":"100%"}})]:_vm._l((_vm.filteredportions),function(portion,index){return _c('line',{key:index,style:({
        strokeWidth: _vm.stroke,
        stroke: portion.color,
      }),attrs:{"x1":_vm.xpoints[index],"x2":_vm.xpoints[index + 1],"y1":_vm.y,"y2":_vm.y}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }