<template>
  <td class="tw-w-10">
    <slot></slot>
  </td>
</template>

<script>
export default {
  name: 'DataTableColumnActions',
}
</script>
