import Vue from 'vue'

// prepared for bigger refactoring

const dateoptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
}

const monthoptions = {
  month: 'long',
}

const timeoptions = {
  year: 'numeric', month: 'numeric', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  hour12: false,
}

Vue.filter('currency', function (value) {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(value)
})

Vue.filter('date', function (value) {
  return new Intl.DateTimeFormat('de-DE', dateoptions).format(value)
})

Vue.filter('month', function (value) {
  return new Intl.DateTimeFormat('de-DE', monthoptions).format(value)
})

Vue.filter('time', function (value) {
  return new Intl.DateTimeFormat('de-DE', timeoptions).format(value)
})

export default {}
