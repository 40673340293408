<template>
  <div>
    <div v-if="availableNodes.length > 1" class="node-selector tw-absolute tw-top-2">
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <div class="selector tw-flex tw-flex-row tw-items-center lg:tw-mr-3">
            <v-btn class="tw-pl-4 tw-pr-3" color="green" outlined v-on="on">
              <span class="button-text tw-hidden sm:tw-block">{{ currentNode.name }}</span>
              <v-icon color="grey">mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </template>
        <div class="tw-bg-white tw-w-64">
          <v-list dense>
            <v-list-item v-for="node in availableNodes" :key="node._id" class="node" :class="{ active: currentNode.id === node.id }">
              <v-list-item-title class="tw-text-base tw-text-ci-gray-600" @click="setSelectedNode(node)">
                {{ node.name}}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </div>
    <div class="tw-absolute tw-top-2 tw-right-2">
      <v-btn
        class="tw-h-10 tw-w-10 lg:tw-hidden"
        icon
        @click.prevent="() => $store.dispatch('hideMenu')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <ul class="snds-nav-list">
      <li v-for="item in envNavItems" :key="item.name">
        <hr v-if="item.type === 'divider'" class="tw-mx-6 tw-my-4" />
        <router-link
          v-if="item.type === 'link'"
          :class="[
            {
              'router-link-exact-active':
                $route.name === item.link ||
                ($route.name && $route.name.includes(item?.parent)),
            },
            item.icon,
          ]"
          :to="{ name: item.link, params: item.params, query: { node: selectedNode } }">
          {{ item.name }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.v-list .v-list-item--active {
  @apply tw-text-ci-green-500;
}

.node.active {
  background: #E0E0E0;
}

.node:hover {
  background: #919090;
  cursor: pointer;
}

.node-selector {
  display: flex;
  width: 100%;
  margin-left:28px;
  margin-top:16px;
}

.selector button {
  width: 230px;
  border:1px solid #E0E0E0;
  background-color: #FFFFFF;
}

.selector button span.button-text {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
}

:deep(.selector button .v-btn__content) {
  width:100%;
  justify-content: space-between;
}

.snds-nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.snds-nav-list a {
  color: #919090;
  display: block;
  font-weight: bold;
  letter-spacing: -0.16px;
  line-height: 21px;
  padding: 11px 0 9px 64px;
  position: relative;
  text-decoration: none;
}

.snds-nav-list a:after {
  background-color: #919090;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  content: '\a0';
  display: block;
  height: 24px;
  left: 28px;
  position: absolute;
  top: 8px;
  width: 24px;
}

.snds-nav-list a:before {
  background: #00a82d;
  content: '\a0';
  display: none;
  height: 31px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 4px;
}

.snds-nav-list a:hover:before,
.snds-nav-list a.router-link-exact-active:before {
  display: block;
}

.snds-nav-list a.router-link-exact-active,
.snds-nav-list a:hover {
  color: #3f3d3f;
}

.snds-nav-list a.router-link-exact-active:after,
.snds-nav-list a:hover:after {
  background-color: #3f3d3f;
}

.snds-nav-list a.snds-dashboard:after {
  mask: url('../assets/icons/icons8-dashboard.svg') no-repeat center;
}

.snds-nav-list a.snds-invoice:after {
  mask: url('../assets/icons/icons8-invoice.svg') no-repeat center;
}

.snds-nav-list a.snds-pending:after {
  mask: url('../assets/icons/icons8-new-letter.svg') no-repeat center;
}

.snds-nav-list a.documents:after {
  mask: url('../assets/icons/document.svg') no-repeat center;
}

.snds-nav-list a.services-management:after {
  mask: url('../assets/icons/file-settings.svg') no-repeat center;
}

.snds-nav-list a.snds-cashbook:after {
  mask: url('../assets/icons/icons8-cashbook.svg') no-repeat center;
}

.snds-nav-list a.snds-simulation:after {
  mask: url('../assets/icons/developer-board.svg') no-repeat center;
}
</style>
<script>
import { mapGetters } from 'vuex'

export default {
  props: ['mini'],
  name: 'Navigation',
  computed: {
    currentNode: function () {
      return this.availableNodes.find((node) => node.id === this.selectedNode)
    },
    envNavItems: function () {
      return this.navItems?.filter((item) => {
        return (
          (!item.env || item.env === config.NODE_ENV) &&
          (!item.device || config.VUE_APP_DEVICE === item.device) &&
          (!item.role || (this.roles && this.roles[item.role]))
        )
      })
    },
    ...mapGetters(['roles', 'availableNodes', 'nodeId', 'selectedNode', 'nodeCustomerID']),
  },
  methods: {
    setSelectedNode(node) {
      this.$store.commit('SET_SELECTED_NODE', node.id)
      this.$router.push({ query: { node: node.id } })
    },
  },
  data() {
    return {
      navItems: [
        {
          type: 'link',
          name: 'Übersicht',
          link: 'home',
          icon: 'snds-dashboard',
        },
        {
          type: 'link',
          name: 'Rechnungen',
          link: 'invoices',
          params: { state: 'open' },
          icon: 'snds-invoice',
        },
        {
          type: 'link',
          name: 'Abrechnungen',
          link: 'billings.pending',
          parent: 'billings',
          icon: 'snds-pending',
        },
        {
          type: 'link',
          name: 'Zahlungsverkehr',
          link: 'transactions',
          icon: 'snds-cashbook',
          params: { transaction_state: 'unassigned' },
        },
        {
          type: 'divider',
        },
        {
          type: 'link',
          name: 'Dokumente',
          link: 'documents',
          icon: 'documents',
        },
        {
          type: 'link',
          name: 'Leistungsverwaltung',
          link: 'services-management.material-costs',
          icon: 'services-management',
        },
      ],
    }
  },
}
</script>
