<template>
    <InfoText :color="color" :icon="icon" :message="message" width="width">
        <slot>{{message}}</slot>
    </InfoText>
</template>
<script>
    import InfoText from "./InfoText";
    export default {
      props: {
        color: {type: String, default: "#FF5252"},
        icon: {type: String, default: "mdi-alert-circle-outline"},
        message: {type: String, default: "Es ist ein Fehler aufgetreten!"},
        width: {}
      },
      components: {InfoText}
    }
</script>
