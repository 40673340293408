<template>
  <Doughnut :data="chartData" :options="chartOptions" />
</template>

<script>
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Doughnut } from 'vue-chartjs'
import { currencyformatter } from './formatter'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
)

export default {
  components: {
    Doughnut,
  },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    chartOptions: {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          backgroundColor: '#FFFFFF',
          bodyColor: '#4D4F5C',
          cornerRadius: 0,
          displayColors: false,
          bodyFont: {
            size: 14,
            weight: 'bold',
          },
          callbacks: {
            title: () => null,
            label: function (context) {
              if (context.dataset.data.length < 2) {
                return 'keine Rechnung vorhanden'
              }
              return currencyformatter.format(context.parsed)
            },
          },
        },
      },
      cutout: '70%',
      maintainAspectRatio: false,
      responsive: true,
    },
  }),
}
</script>
