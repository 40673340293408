<template>
  <v-textarea
    :label="generatedLabel"
    class="element"
    color="#00A82D"
    dense
    outlined
    v-bind="$attrs"
    v-on="$listeners" />
</template>

<script>
export default {
  name: 'InputTextarea',
  inehritAttrs: false,
  computed: {
    generatedLabel() {
      if (!this.$attrs.label) {
        return null
      }
      return `${this.$attrs.label}${
        this.$attrs.required !== undefined ? '*' : ''
      }`
    },
  },
}
</script>

<style scoped>
.element >>> textarea {
  @apply tw-leading-tight tw-py-1;
}
</style>
