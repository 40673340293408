<template>
  <button
    v-ripple
    :disabled="disabled || loading"
    class="tw-bg-ci-gray-500 hover:tw-bg-ci-gray-500/90 tw-text-white tw-py-2 tw-px-5 tw-rounded-full tw-outline-0 disabled:tw-bg-ci-gray-200"
    data-test-buttons-secondary-button
    v-bind="$attrs"
    v-on="$listeners">
    <span
      :class="{ 'tw-opacity-0': loading }"
      class="tw-transition-opacity tw-duration-200">
      <slot></slot>
    </span>
    <transition name="fade">
      <span
        v-if="loading"
        class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
        <v-icon class="tw-animate-spin tw-text-white">mdi-loading</v-icon>
      </span>
    </transition>
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style>
.fade-enter-active {
  transition: transform 0.2s ease-in-out;
}

.fade-enter-from {
  transform: scale(0.8);
}

.fade-enter-to {
  transform: scale(1);
}
</style>
