const state = {
  editInvoice: undefined,
  deleteInvoice: undefined,
  previewInvoice: undefined,
  duplicateInvoice: undefined,
  reminderPreview: undefined,
}

const getters = {
  isEditInvoiceDialogVisible: (state) => {
    return !!state.editInvoice
  },
  invoiceToEdit: (state) => {
    return state.editInvoice
  },
  isDeleteInvoiceDialogVisible: (state) => {
    return !!state.deleteInvoice
  },
  invoiceToDelete: (state) => {
    return state.deleteInvoice
  },
  invoiceToPreview: (state) => {
    return state.previewInvoice
  },
  isInvoicePreviewDialogVisible: (state) => {
    return !!state.previewInvoice
  },
  invoiceToDuplicate: (state) => {
    return state.duplicateInvoice
  },
  isDuplicateInvoiceDialogVisible: (state) => {
    return !!state.duplicateInvoice
  },
  isReminderPreviewDialogVisible: (state) => {
    return !!state.reminderPreview
  },
  reminderToPreview: (state) => {
    return state.reminderPreview
  },
}

const mutations = {
  setEditInvoice(state, payload) {
    state.editInvoice = payload
  },
  setDeleteInvoice(state, payload) {
    state.deleteInvoice = payload
  },
  setPreviewInvoice(state, payload) {
    state.previewInvoice = payload
  },
  setDuplicateInvoice(state, payload) {
    state.duplicateInvoice = payload
  },
  setPreviewReminder(state, payload) {
    state.reminderPreview = payload
  },
}

const actions = {
  showEditInvoiceDialog({ commit }, invoice) {
    commit('setEditInvoice', invoice)
  },
  closeEditInvoiceDialog({ commit }) {
    commit('setEditInvoice', null)
  },
  showDeleteInvoiceDialog({ commit }, invoice) {
    commit('setDeleteInvoice', invoice)
  },
  closeDeleteInvoiceDialog({ commit }) {
    commit('setDeleteInvoice', null)
  },
  showInvoicePreviewDialog({ commit }, invoice) {
    commit('setPreviewInvoice', invoice)
  },
  closeInvoicePreviewDialog({ commit }) {
    commit('setPreviewInvoice', null)
  },
  showDuplicateInvoiceDialog({ commit }, invoice) {
    commit('setDuplicateInvoice', invoice)
  },
  closeDuplicateInvoiceDialog({ commit }) {
    commit('setDuplicateInvoice', null)
  },
  showReminderPreviewDialog({ commit }, reminder) {
    commit('setPreviewReminder', reminder)
  },
  closeReminderPreviewDialog({ commit }) {
    commit('setPreviewReminder', null)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
