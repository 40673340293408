<template>
  <footer class="tw-px-4 lg:tw-px-8 tw-py-5 tw-border-t-2 tw-border-t-gray-200 tw-space-x-5">
    <slot></slot>
  </footer>
</template>

<script>
export default {
  name: 'DialogFooter',
}
</script>
