<template>
  <svg :style="{ height: `${stroke}px` }" class="tw-w-full tw-block">
    <template v-if="loading">
      <line
        :style="{
          strokeWidth: stroke,
          stroke: 'rgba(0, 0, 0, 0.12)',
        }"
        :y1="y"
        :y2="y"
        x1="0"
        x2="100%" />
    </template>
    <template v-else>
      <line
        v-for="(portion, index) of filteredportions"
        :key="index"
        :style="{
          strokeWidth: stroke,
          stroke: portion.color,
        }"
        :x1="xpoints[index]"
        :x2="xpoints[index + 1]"
        :y1="y"
        :y2="y" />
    </template>
  </svg>
</template>

<script>
export default {
  props: ['portions', 'stroke', 'loading'],
  computed: {
    filteredportions: function () {
      return this.portions.filter((p) => p.proportion > 0)
    },
    height: function () {
      return this.stroke + 'px'
    },
    y: function () {
      return Math.floor(this.stroke / 2)
    },
    xpoints: function () {
      const proportionsum = this.filteredportions.reduce((acc, cur) => {
        acc += cur.proportion
        return acc
      }, 0)
      const points = [0]
      this.filteredportions.reduce((x1, cur) => {
        const x2 = x1 + Math.floor((cur.proportion / proportionsum) * 100)
        points.push('' + x2 + '%')
        return x2
      }, 0)
      points[points.length - 1] = '100%'
      return points
    },
  },
}
</script>
