<template>
  <div v-if="app">
    <slot></slot>
  </div>
</template>
<style scoped>
  div {
    margin:0;
    padding: 0;
  }
</style>
<script>
  export default {
    computed: {
      app () {
        return config.VUE_APP_DEVICE === 'app'
      }
    }
  }
</script>
