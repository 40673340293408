<template>
  <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-end lg:tw-space-x-5 tw-space-y-5 lg:tw-space-y-0">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DialogActions',
}
</script>
