<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       width="179.483"
       height="42.305"
       viewBox="0 0 179.483 42.305"
       class="tw-fill-current">
    <g transform="translate(-3.4 -283.466)">
      <path class="a"
            d="M104.609,311.354a9.009,9.009,0,0,0-4.5-1.182c-1.053,0-1.357.439-1.357,1.047,0,.783.439,1.087,2.066,1.864,3.378,1.627,4.645,2.883,4.645,5.067,0,2.815-2.066,4.645-5.591,4.645a14.577,14.577,0,0,1-6.238-1.256l.563-3.457a11.576,11.576,0,0,0,5.152,1.526c.98,0,1.385-.507,1.385-1.053,0-.676-.169-1.013-1.492-1.622-3.727-1.689-5.214-3.085-5.214-5.6,0-2.815,2.438-4.47,6-4.47a11.121,11.121,0,0,1,5.118,1.087Z"
            transform="translate(-39.428 -10.223)"/>
      <path class="a"
            d="M123.466,322.726c-3.693,0-6-2.579-6-7.049,0-5.422,3.592-8.541,9.572-8.541a16.057,16.057,0,0,1,5.253.743v9.628c0,2.1.27,4.881.27,4.881h-3.9s-.068-.918-.135-2a6.283,6.283,0,0,1-5.067,2.342Zm4.544-11.39a8.746,8.746,0,0,0-1.931-.2c-2.41,0-4,1.689-4,4.544,0,1.9.985,2.911,2.477,2.911,1.864,0,3.457-1.689,3.457-4.2Z"
            transform="translate(-49.845 -10.341)"/>
      <path class="a"
            d="M154.147,322.34h-4.5V312.206a14.481,14.481,0,0,0-.743-4.78h4.5a6.232,6.232,0,0,1,.479,1.661,7.077,7.077,0,0,1,4.943-2.066c2.883,0,5.118,2.2,5.118,5.726v9.572h-4.707v-8.755c0-2.41-1.357-2.545-2-2.545s-3.08.479-3.08,4.915Z"
            transform="translate(-63.579 -10.292)"/>
      <path class="a"
            d="M186.346,322.726c-3.693,0-6-2.579-6-7.049,0-5.422,3.592-8.541,9.572-8.541a16.057,16.057,0,0,1,5.253.743v9.628c0,2.1.27,4.881.27,4.881h-3.9s-.068-.918-.135-2a6.284,6.284,0,0,1-5.067,2.342Zm4.544-11.39a8.746,8.746,0,0,0-1.931-.2c-2.41,0-4,1.689-4,4.544,0,1.9.985,2.911,2.477,2.911,1.864,0,3.457-1.689,3.457-4.2Z"
            transform="translate(-77.322 -10.341)"/>
      <path class="a"
            d="M218.188,316.24c-3.941,0-6.638-2.849-6.638-7.151,0-4.949,3.254-8.507,7.657-8.507a8.733,8.733,0,0,1,3.153.563V292.21H226.7v18.507c0,1.526.338,5.152.338,5.152h-3.727a13.551,13.551,0,0,1-.2-1.762A6.1,6.1,0,0,1,218.188,316.24Zm4.172-11.261a5.883,5.883,0,0,0-2.376-.411c-2.2,0-3.727,1.689-3.727,4.375,0,1.965,1.087,3.147,2.68,3.147,1.931,0,3.423-1.182,3.423-4.876Z"
            transform="translate(-90.955 -3.821)"/>
      <path class="a"
            d="M255.681,321.415a9.369,9.369,0,0,1-5.118,1.391c-4.544,0-7.353-2.849-7.353-7.319,0-4.983,3.322-8.406,8.1-8.406,3.322,0,5.523,1.762,5.523,4.476,0,3.08-3.181,5.112-8.029,5.112-.2,0-.507-.034-.918-.034a2.843,2.843,0,0,0,3.052,2.714,9.4,9.4,0,0,0,4.746-1.492Zm-4.848-10.878c-1.357,0-2.438,1.188-2.714,3.018a4.867,4.867,0,0,0,.952.068c2.134,0,3.254-.563,3.254-1.728A1.441,1.441,0,0,0,250.833,310.537Z"
            transform="translate(-104.789 -10.318)"/>
      <path class="a"
            d="M281.036,307.736h4.5V317.87a14.481,14.481,0,0,0,.743,4.78h-4.5a6.031,6.031,0,0,1-.473-1.689,6.756,6.756,0,0,1-4.775,2.066c-2.883,0-5.152-2.337-5.152-5.726,0-3.834.405-6.238,0-9.572h4.707a84.523,84.523,0,0,1,0,8.778c0,2.477,1.391,2.545,2.033,2.545s2.916-.479,2.916-4.915Z"
            transform="translate(-117.099 -10.602)"/>
      <path class="a"
            d="M312.149,311.354a9.008,9.008,0,0,0-4.5-1.182c-1.053,0-1.357.439-1.357,1.047,0,.783.439,1.087,2.066,1.864,3.378,1.627,4.645,2.883,4.645,5.067,0,2.815-2.066,4.645-5.591,4.645a14.576,14.576,0,0,1-6.238-1.256l.563-3.457a11.571,11.571,0,0,0,5.146,1.526c.985,0,1.391-.507,1.391-1.053,0-.676-.169-1.013-1.492-1.622-3.727-1.689-5.219-3.085-5.219-5.6,0-2.815,2.444-4.47,6-4.47a11.12,11.12,0,0,1,5.118,1.087Z"
            transform="translate(-130.116 -10.223)"/>
      <path class="b"
            d="M27.779,295.461a10.562,10.562,0,0,1,13.929,3.074v-1.391a6.194,6.194,0,0,0-3.109-5.372L25.651,284.3a6.193,6.193,0,0,0-6.193,0l-12.95,7.477A6.193,6.193,0,0,0,3.4,297.144v12.387Z"
            transform="translate(0)"/>
      <path class="b"
            d="M17.251,326.335A10.579,10.579,0,0,1,3.4,323.413v1.244a6.193,6.193,0,0,0,3.108,5.377l12.95,7.471a6.193,6.193,0,0,0,6.193,0l12.95-7.471a6.193,6.193,0,0,0,3.108-5.377V312.22Z"
            transform="translate(0 -12.564)"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
}
</script>
