<template>
  <Bar :data="chartData" :options="chartOptions" />
</template>

<script>
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'vue-chartjs'
import { currencyformatter } from './formatter'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  components: {
    Bar,
  },
  props: {
    chartData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    chartOptions: {
      animations: {
        numbers: {
          type: 'number',
          properties: ['y'],
        },
      },
      scales: {
        y: {
          display: false,
        },
        x: {
          beginAtZero: true,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        grid: {
          display: false,
        },
        tooltip: {
          backgroundColor: '#FFFFFF',
          bodyColor: '#4D4F5C',
          cornerRadius: 0,
          yAlign: 'bottom',
          xAlign: 'center',
          displayColors: false,
          bodyFont: {
            size: 14,
            weight: 'bold',
          },
          callbacks: {
            title: () => null,
            label: function (context) {
              return currencyformatter.format(context.parsed.y)
            },
          },
        },
      },
      maintainAspectRatio: false,
      responsive: true,
    },
  }),
}
</script>
