import Vue from 'vue'
import Vuex, { createLogger } from 'vuex'
import actions from './actions'
import getters from './getters'

import billings from './modules/billings'
import invoice from './modules/invoice'
import materialCosts from './modules/materialCosts'

import mutations from './mutations'
import state from './state'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
  modules: {
    billings,
    invoice,
    materialCosts,
  },
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})
