const state = {
  expandedBillings: [],
  uploadBilling: undefined,
  selectAttachments: undefined,
  emptyBillingCreationProcessStarted: undefined,
}

const getters = {
  expandedBillings: (state) => state.expandedBillings,
  isUploadBillingDialogVisible: (state) => {
    return !!state.uploadBilling
  },
  isSelectAttachmentsDialogVisible: (state) => {
    return !!state.selectAttachments
  },
  billingIdForAttachments: (state) => {
    return state.selectAttachments
  },
  isEmptyBillingCreationProcessStarted: (state) => {
    return !!state.emptyBillingCreationProcessStarted
  },
  billingIdOfStartedCreationProcess: (state) => {
    console.log(state.emptyBillingCreationProcessStarted)
    return state.emptyBillingCreationProcessStarted
  },
}

const mutations = {
  setExpandedBilling(state, billingId) {
    state.expandedBillings.push(billingId)
  },
  unsetExpandedBilling(state, billingId) {
    state.expandedBillings = state.expandedBillings.filter(
      (id) => id !== billingId,
    )
  },
  setUploadBilling(state, payload) {
    state.uploadBilling = payload
  },
  setSelectAttachments(state, payload) {
    state.selectAttachments = payload
  },
  setIsEmptyBillingCreationStarted(state, payload) {
    state.emptyBillingCreationProcessStarted = payload
  },
}

const actions = {
  setExpandedBilling({ commit }, billingId) {
    commit('setExpandedBilling', billingId)
  },
  unsetExpandedBilling({ commit }, billingId) {
    commit('unsetExpandedBilling', billingId)
  },
  showUploadBillingDialog({ commit }) {
    commit('setUploadBilling', true)
  },
  closeUploadBillingDialog({ commit }) {
    commit('setUploadBilling', false)
  },
  showSelectAttachmentsDialog({ commit }, billingId) {
    commit('setSelectAttachments', billingId)
  },
  closeSelectAttachmentsDialog({ commit }) {
    commit('setSelectAttachments', undefined)
  },
  startEmptyBillingCreationProcess({ commit }, billingId) {
    commit('setIsEmptyBillingCreationStarted', billingId)
  },
  unsetEmptyBillingCreationProcess({ commit }) {
    commit('setIsEmptyBillingCreationStarted', undefined)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
