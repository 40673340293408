<template>
  <div
    v-if="loading"
    class="tw-z-1 tw-flex tw-flex-col tw-justify-center tw-items-center tw-pointer-events-none">
    <v-progress-circular color="green" indeterminate />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['loading'],
}
</script>
