<template>
    <InfoText :color="color" :icon="icon" :message="message" :width="width"><slot>{{message}}</slot></InfoText>
</template>
<script>
  import InfoText from "./InfoText";
  export default {
    components: {InfoText},
    width: {type: String},
    props: {
      color: {type: String, default: "#00A82D"},
      icon: {type: String, default: "mdi-information-outline"},
      message: {type: String, default: "Es liegen keine Daten vor..."},
      width: {}
    }
  }
</script>
