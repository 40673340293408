import PrimaryButton from '@/components/Buttons/PrimaryButton'
import SecondaryButton from '@/components/Buttons/SecondaryButton'
import StrokedButton from '@/components/Buttons/StrokedButton'
import DataTable from '@/components/DataTable/DataTable'
import DataTableColumn from '@/components/DataTable/DataTableColumn'
import DataTableColumnActions from '@/components/DataTable/DataTableColumnActions'
import DataTableHeader from '@/components/DataTable/DataTableHeader'
import DataTableHeaderActions from '@/components/DataTable/DataTableHeaderActions'
import DataTableHeaderDummy from '@/components/DataTable/DataTableHeaderDummy'
import DialogActions from '@/components/Dialog/DialogActions'
import DialogBody from '@/components/Dialog/DialogBody'
import DialogContainer from '@/components/Dialog/DialogContainer'
import DialogFooter from '@/components/Dialog/DialogFooter'
import DialogHeader from '@/components/Dialog/DialogHeader'
import GenericConfirmationDialog from '@/components/Dialogs/GenericConfirmationDialog'
import InputDate from '@/components/Forms/InputDate'
import InputPassword from '@/components/Forms/InputPassword'
import InputSelect from '@/components/Forms/InputSelect'
import InputText from '@/components/Forms/InputText'
import InputTextarea from '@/components/Forms/InputTextarea'
import Amount from '@/components/helper/Amount'
import Date from '@/components/helper/Date'
import InfoText from '@/components/helper/InfoText'
import NoDataInfoText from '@/components/helper/NoDataInfoText'
import StatusLine from '@/components/helper/StatusLine'
import Time from '@/components/helper/Time'
import WarningText from '@/components/helper/WarningText'
import Loader from '@/components/Loader'
import Vue from 'vue'

// Dialog
Vue.component('DialogContainer', DialogContainer)
Vue.component('DialogFooter', DialogFooter)
Vue.component('DialogBody', DialogBody)
Vue.component('DialogHeader', DialogHeader)
Vue.component('DialogActions', DialogActions)
Vue.component('GenericConfirmationDialog', GenericConfirmationDialog)

// Buttons
Vue.component('PrimaryButton', PrimaryButton)
Vue.component('SecondaryButton', SecondaryButton)
Vue.component('StrokedButton', StrokedButton)

// Data tables
Vue.component('DataTable', DataTable)
Vue.component('DataTableHeader', DataTableHeader)
Vue.component('DataTableHeaderActions', DataTableHeaderActions)
Vue.component('DataTableHeaderDummy', DataTableHeaderDummy)
Vue.component('DataTableColumn', DataTableColumn)
Vue.component('DataTableColumnActions', DataTableColumnActions)

// Misc
Vue.component('Loader', Loader)
Vue.component('Amount', Amount)
Vue.component('Time', Time)
Vue.component('Date', Date)
Vue.component('StatusLine', StatusLine)

// Forms
Vue.component('InputDate', InputDate)
Vue.component('InputPassword', InputPassword)
Vue.component('InputSelect', InputSelect)
Vue.component('InputText', InputText)
Vue.component('InputTextarea', InputTextarea)

// Helper
Vue.component('NoDataInfoText', NoDataInfoText)
Vue.component('WarningText', WarningText)
Vue.component('InfoText', InfoText)

export default {}
