<template>
  <div
    ref="container"
    :class="containerExtraClasses"
    class="tw-relative tw-z-0">
    <div v-if="searchable" :class="searchWrapperExtraClasses">
      <div class="tw-h-10 tw-w-full">
        <InputText
          v-model="searchTerm"
          :placeholder="searchPlaceholder"
          clear-icon="mdi-close-circle"
          clearable
          rounded
          @click.clear="clearSearchTerm" />
      </div>
    </div>
    <div v-if="filter.length" class="tw-flex lg:tw-flex-row tw-pt-2">
      <div v-for="_filter in filter" :key="`filter.${_filter}`">
        <InputSelect
          :items="getAvailableFilterValues(_filter)"
          :label="getFilterLabel(_filter)"
          class="tw-h-10"
          clearable
          @change="filterSelectionChanged(_filter, $event)" />
      </div>
    </div>
    <div
      ref="tableContainer"
      :class="tableWrapperExtraClasses"
      class="tw-max-w-full">
      <table
        :class="{ 'snds-data-table--with-actions': hasActions }"
        class="tw-w-full snds-data-table tw-border-separate tw-border-spacing-0">
        <thead :class="headExtraClasses">
          <tr>
            <slot
              v-for="(header, i) in headers"
              :header="header"
              :name="`header(${header.value})`">
              <DataTableHeader :key="`header.${i}`" :header="header" />
            </slot>
          </tr>
        </thead>
        <draggable
          :class="bodyExtraClasses"
          :disabled="!draggable"
          :list="sortedItems"
          tag="tbody"
          @sort="itemsOrderChanged">
          <template v-if="!draggable">
            <template v-for="(item, rowIndex) in sortedItems">
              <tr
                :key="`row.${rowIndex}`"
                :class="{
                  'tw-group-expanded':
                    hasExpansion && isExpanded(rowIndex, item),
                  'tw-cursor-pointer':
                    hasExpansion || selectable || hasRowClickCallback,
                  'tw-group-selected': selectable && isSelected(rowIndex, item),
                }"
                class="tw-group"
                @click.prevent="handleRowClick(rowIndex, item)"
                @contextmenu.prevent="handleRowRightClick($event, item)">
                <slot
                  v-for="(key, columnIndex) of fields"
                  :index="columnIndex"
                  :item="item"
                  :name="`cell(${key})`"
                  :rowIndex="rowIndex"
                  :value="item[key]">
                  <DataTableColumn :key="`cell.${rowIndex}.${columnIndex}`">
                    {{ item[key] }}
                  </DataTableColumn>
                </slot>
              </tr>
              <tr v-if="hasStatusLine" :key="`row.${rowIndex}.statusline`">
                <td :colspan="fields.length">
                  <slot :item="item" name="statusline" />
                </td>
              </tr>
              <tr
                v-if="hasExpansion && isExpanded(rowIndex, item)"
                :key="`row.${rowIndex}.expansion`">
                <td :colspan="fields.length">
                  <slot :item="item" name="expansion" />
                </td>
              </tr>
            </template>
          </template>
          <template v-else>
            <tr
              v-for="(item, rowIndex) in sortedItems"
              :key="`row.${rowIndex}`"
              class="tw-group tw-cursor-grab active:tw-cursor-grabbing"
              @contextmenu.prevent="handleRowRightClick($event, item)">
              <slot
                v-for="(key, j) of fields"
                :index="j"
                :item="item"
                :name="`cell(${key})`"
                :rowIndex="rowIndex"
                :value="item[key]">
                <DataTableColumn :key="`cell.${rowIndex}.${j}`">
                  {{ item[key] }}
                </DataTableColumn>
              </slot>
            </tr>
          </template>
        </draggable>
        <template v-if="loading">
          <tbody>
            <tr v-for="(_, rowIndex) in skeletonItems" :key="`row.${rowIndex}`">
              <slot
                v-for="(key, columnIndex) of fields"
                :index="columnIndex"
                :name="`skeleton(${key})`"
                :rowIndex="rowIndex">
                <DataTableColumn :key="`cell.${rowIndex}.${columnIndex}`">
                  Lädt
                </DataTableColumn>
              </slot>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
    <div
      v-if="items && !sortedItems.length && !loading"
      :class="{ 'tw-px-4': embedded }"
      class="tw-pt-4">
      <slot name="empty">
        <NoDataInfoText color="info" />
      </slot>
    </div>
    <v-menu
      v-model="contextmenu"
      :position-x="contextmenuX"
      :position-y="contextmenuY"
      absolute
      close-on-click>
      <slot v-if="contextmenuItem" :item="contextmenuItem" name="contextmenu" />
    </v-menu>
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import { debounce } from 'lodash'
import draggable from 'vuedraggable'

export default {
  name: 'DataTable',
  components: {
    draggable,
  },
  props: {
    loading: {
      type: Boolean,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    allowMultipleExpansions: {
      type: Boolean,
      default: false,
    },
    searchWrapperExtraClasses: {
      type: String,
    },
    containerExtraClasses: {
      type: String,
    },
    searchPlaceholder: {
      type: String,
      default: 'Suchen',
    },
    tableWrapperExtraClasses: {
      type: String,
      default: 'tw-overflow-x-auto lg:tw-overflow-x-visible',
    },
    headExtraClasses: {
      type: String,
    },
    bodyExtraClasses: {
      type: String,
    },
    initialSortDirection: {
      type: String,
      default: 'desc',
    },
    initialExpandedItems: {
      type: Array,
      default: () => [],
    },
    useComponentSorting: {
      type: Boolean,
      default: true,
    },
    useComponentSearch: {
      type: Boolean,
      default: true,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Array,
      default: () => [],
    },
    rowClickCallback: {
      type: Function,
      default: undefined,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
    skeletonItems: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      expandedItems: [],
      selectedItems: [],
      currentSortKey: null,
      currentSortType: null,
      currentSortDirection: this.initialSortDirection,
      scrollSelector: null,
      searchTerm: null,
      activeFilter: [],
      previousScrollY: null,
      scrollThreshold: null,
      contextmenu: null,
      contextmenuItem: null,
      contextmenuX: 0,
      contextmenuY: 0,
    }
  },
  computed: {
    filteredItems() {
      if (this.filter.length && this.activeFilter.length) {
        return this.items.filter((item) => {
          return this.activeFilter.every((filter) => {
            return item[filter.key] === filter.value
          })
        })
      }
      return this.filteredItems
    },
    searchedItems() {
      if (!this.useComponentSearch || !this.searchable || !this.searchTerm) {
        return this.items
      }
      return this.filteredItems.filter((item) => {
        return Object.keys(item).some((key) => {
          return String(item[key])
            .toLowerCase()
            .includes(this.searchTerm.toLowerCase())
        })
      })
    },
    sortedItems() {
      if (!this.useComponentSorting || !this.currentSortKey) {
        return this.searchedItems
      }
      return JSON.parse(JSON.stringify(this.searchedItems)).sort((a, b) => {
        const aValue = this.getParsedValue(a[this.currentSortKey])
        const bValue = this.getParsedValue(b[this.currentSortKey])
        if (aValue === bValue) {
          return 0
        }
        if (aValue > bValue) {
          return this.currentSortDirection === 'asc' ? 1 : -1
        }
        return this.currentSortDirection === 'asc' ? -1 : 1
      })
    },
  },
  watch: {
    currentSortDirection: function () {
      this.$emit('sort', {
        key: this.currentSortKey,
        direction: this.currentSortDirection,
      })
      EventBus.$emit('resetScrollPosition')
    },
    currentSortKey: function () {
      this.$emit('sort', {
        key: this.currentSortKey,
        direction: this.currentSortDirection,
      })
      EventBus.$emit('resetScrollPosition')
    },
    searchTerm: debounce(function (_, oldValue) {
      if (oldValue === null) {
        return
      }
      this.clearExpandedItems()
      this.clearSelectedItems()
      this.$emit('search', this.searchTerm)
    }, 200),
    contextmenu: function (value) {
      if (!value) {
        this.contextmenuItem = null
      }
    },
    initialExpandedItems: function (value) {
      this.expandedItems = [...this.expandedItems, ...value]
    },
  },
  methods: {
    filterSelectionChanged(key, value) {
      if (this.activeFilter.find((item) => item.key === key)) {
        if (!value) {
          this.activeFilter = this.activeFilter.filter(
            (item) => item.key !== key,
          )
          return
        }
        this.activeFilter.find((item) => item.key === key).value = value
      } else {
        this.activeFilter.push({
          key,
          value,
        })
      }
    },
    clearSelectedItems(soft = true) {
      this.selectedItems = []
      if (soft) {
        return
      }
      this.$emit('selected', null)
    },
    clearExpandedItems() {
      this.expandedItems = []
    },
    clearSearchTerm() {
      this.searchTerm = ''
    },
    itemsOrderChanged(newOrder) {
      this.$emit('orderChanged', newOrder)
    },
    getParsedValue(value) {
      switch (this.currentSortType) {
        case 'number' || 'int':
          if (typeof value === 'object') {
            value = value.length
          }
          return parseInt(value, 10)
        case 'float':
          return parseFloat(value)
        default:
          return `${value}`.toLowerCase()
      }
    },
    getFilterLabel(key) {
      return this.headers.find((header) => header.value === key).text
    },
    getAvailableFilterValues(key) {
      return [
        ...new Set(
          this.items.map((item) => ({
            text: this.$t(item[key]),
            value: item[key],
          })),
        ),
      ]
    },
    handleRowRightClick($event, item) {
      this.contextmenu = true
      this.contextmenuItem = item
      this.contextmenuX = $event.clientX
      this.contextmenuY = $event.clientY
    },
    handleRowClick(index, item) {
      const identifier = item._id || index
      if (document.getSelection().toString().length) {
        return
      }
      if (this.rowClickCallback) {
        this.rowClickCallback(item)
        return
      }
      if (this.hasExpansion) {
        if (this.expandedItems.includes(identifier)) {
          this.$emit('expanded', identifier, false)
          this.expandedItems = this.expandedItems.filter(
            (i) => i !== identifier,
          )
          return
        }
        if (!this.allowMultipleExpansions) {
          this.expandedItems = []
        }
        this.$emit('expanded', identifier, true)
        this.expandedItems.push(identifier)
      }
      if (this.selectable) {
        if (this.selectedItems.includes(identifier)) {
          this.selectedItems = this.selectedItems.filter(
            (i) => i !== identifier,
          )
          this.$emit('selected', null)
        } else {
          this.selectedItems = [identifier]
          this.$emit('selected', item)
        }
      }
    },
    isExpanded(index, item) {
      const identifier = item._id || index
      return this.expandedItems.includes(identifier)
    },
    isSelected(index, item) {
      const identifier = item._id || index
      return this.selectedItems.includes(identifier)
    },
    endOfContentHandler() {
      this.$emit('endOfTable', true)
    },
    endOfTableHandler: debounce(function () {
      const scrollPositionY =
        this.$refs.tableContainer.scrollTop +
        this.$refs.tableContainer.clientHeight
      const scrollDown = scrollPositionY > this.previousScrollY
      if (
        scrollDown &&
        scrollPositionY > this.$refs.tableContainer.scrollHeight - 100
      ) {
        this.$emit('endOfTable', true)
      }
      this.previousScrollY = scrollPositionY
    }, 50),
    tableHasFixedHeight() {
      return this.$refs.tableContainer.className
        .split(' ')
        .some((className) => /h-\d{1,2}/.test(className))
    },
  },
  setup(props, { slots }) {
    const hasStatusLine = !!slots.statusline
    const hasContextmenu = !!slots.contextmenu
    const hasExpansion = !!slots.expansion
    const hasRowClickCallback = !!props.rowClickCallback
    const hasActions = !!slots['cell(actions)']
    const fields = props.headers.map((header) => header.value)
    return {
      fields,
      hasStatusLine,
      hasContextmenu,
      hasExpansion,
      hasActions,
      hasRowClickCallback,
    }
  },
  mounted() {
    this.expandedItems = [...this.initialExpandedItems]
    EventBus.$on('endOfContent', this.endOfContentHandler)
    if (this.tableHasFixedHeight()) {
      this.$refs.tableContainer.addEventListener(
        'scroll',
        this.endOfTableHandler,
      )
    }
  },
  beforeDestroy() {
    EventBus.$off('endOfContent', this.endOfContentHandler)
    if (this.tableHasFixedHeight()) {
      this.$refs.tableContainer.removeEventListener(
        'scroll',
        this.endOfTableHandler,
      )
    }
  },
}
</script>
