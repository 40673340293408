<template>
  <v-menu v-model="menu"
          :close-on-content-click="false"
          :nudge-left="0"
          transition="scale-transition"
          offset-y
          min-width="auto">
    <template v-slot:activator="{ attrs, on }">
      <h2 :class="headlineExtraClasses"
          v-bind="attrs"
          v-on="on">
        {{ formatMonth(month) }}
        <v-icon class="-tw-mt-1"
                large>
          mdi-chevron-down
        </v-icon>
      </h2>
    </template>
    <v-date-picker v-model="month"
                   color="#00A82D"
                   type="month"
                   @change="updateValue()"
                   locale="de"
                   no-title />
  </v-menu>
</template>

<script>
import { monthformatter } from './formatter'

export default {
  props: [
    'value',
    'headlineExtraClasses',
  ],
  data: function () {
    return {
      menu: false,
      month: this.value.toISOString().substring(0, 7),
    }
  },
  methods: {
    updateValue: function () {
      this.$emit('input', new Date(this.month))
      this.menu = false
    },
    formatMonth: function (isostr) {
      const date = new Date(isostr)
      return monthformatter.format(date) + ' ' + date.getFullYear()
    },
  },
}
</script>
