<template>
  <v-text-field :label="`${label}${(required) ? '*' : ''}`"
                color="#00A82D"
                outlined
                :type="showPassword ? 'text' : 'password'"
                :rules="rules"
                dense
                :append-icon="!toggleable ? null : showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="!toggleable ? false : showPassword = ! showPassword"
                v-model="content"
                :required="required"
                @input="updateValue"
                data-test-forms-input-password/>
</template>

<script>
export default {
  name: 'InputPassword',
  data() {
    return {
      content: this.value,
      showPassword: false,
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', this.content)
    },
  },
  watch: {
    value: {
      handler: function (val) {
        this.content = val
      },
      deep: true,
    },
  },
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    prependInnerIcon: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    toggleable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
