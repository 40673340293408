<template>
  <div class="tw-flex tw-flex-col tw-h-full tw-overflow-auto">
    <template v-if="transaction">
      <TransactionRowDetail
        :parent_item="transaction"
        without-proposals></TransactionRowDetail>
      <v-tabs v-model="tab" class="lg:tw-pr-8" slider-color="green">
        <v-tab
          v-for="tab in tabs"
          :key="tab.index"
          :ripple="false"
          class="before:tw-hidden hover:tw-text-ci-gray-700 tw-transition-colors tw-ml-4 lg:tw-ml-8"
          @click="tabChanged(tab.index)">
          {{ tab.name }}
        </v-tab>
        <div class="searchfield_wrapper">
          <input
            v-model="searchtextfilter"
            class="searchfield"
            placeholder="Rechnungen durchsuchen"
            type="text" />
        </div>
      </v-tabs>
      <v-tabs-items
        v-model="tab"
        class="tw-border-t tw-border-b-ci-gray-150 tw-overflow-auto">
        <v-tab-item v-for="tab in tabs" :key="tab.index" :transition="false">
          <template v-if="tab.index === 1">
            <Invoices
              :invoices="proposals"
              :transaction="transaction"></Invoices>
          </template>
          <template v-if="tab.index === 2 || tab.index === 3">
            <div
              v-if="$apollo.queries.node_invoices.loading"
              class="loadingspinnerwrapper">
              <v-progress-circular
                :size="40"
                :width="5"
                color="#00A82D"
                indeterminate></v-progress-circular>
            </div>
            <template v-else>
              <Invoices
                :invoices="node_invoices"
                :transaction="transaction"></Invoices>
            </template>
            <div
              v-if="node_invoices && node_invoices.length > 99"
              class="moreitemshint">
              Es werden maximal 100 Rechnungen angezeigt. Verwenden Sie die
              Filter, um die Suche weiter einzuschränken.
            </div>
          </template>
          <template v-if="tab.index === 4">
            <Invoices
              :invoices="assigned"
              :transaction="transaction"></Invoices>
          </template>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <div v-else class="loadingspinnerwrapper">
      <v-progress-circular
        :size="40"
        :width="5"
        color="#00A82D"
        indeterminate></v-progress-circular>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import moment from 'moment'
import Invoices from './Invoices'
import TransactionRowDetail from './TransactionRowDetail'
import {mapGetters} from "vuex";

export default {
  components: {
    TransactionRowDetail,
    Invoices,
  },
  props: ['transactionid'],
  data: function () {
    return {
      tab: null,
      tabs: [
        {
          index: 1,
          name: 'Vorschläge',
        },
        {
          index: 2,
          name: 'Offen',
        },
        {
          index: 3,
          name: 'Alle',
        },
        {
          index: 4,
          name: 'Zugewiesen',
        },
      ],
      searchfilter: {
        financial_states: ['open', 'due', 'overdue'],
        patient: '',
      },
      transaction: null,
      searchtextfilter: null,
      lastKeyHit: null,
      refetchTriggered: false,
    }
  },
  watch: {
    transactionid: function () {
      this.transaction = null
      this.$apollo.queries.transaction.refetch()
    },
    searchtextfilter: function () {
      this.lastKeyHit = new Date().getTime()
      !this.refetchTriggered && this.triggerRefetch()
    },
  },
  computed: {
    ...mapGetters(['selectedNode']),
    assigned: function () {
      return this.transaction.invoices
    },
    proposals: function () {
      return this.transaction.proposals.filter((invoice) => {
        const fullname = (
          invoice.firstname + invoice.lastname
        ).toLocaleLowerCase()
        return this.searchfilter.patient
          .split(' ')
          .every(
            (searchstr) => fullname.indexOf(searchstr.toLocaleLowerCase()) >= 0,
          )
      })
    },
  },
  methods: {
    tabChanged: function (tabindex) {
      if (tabindex === 2) {
        this.searchfilter.financial_states = ['open', 'due', 'overdue']
        this.$apollo.queries.node_invoices.skip = false
        this.$apollo.queries.node_invoices.refetch()
      } else if (tabindex === 3) {
        this.searchfilter.financial_states = []
        this.$apollo.queries.node_invoices.skip = false
        this.$apollo.queries.node_invoices.refetch()
      }
    },
    triggerRefetch: function () {
      this.refetchTriggered = true
      setTimeout(() => {
        if (new Date().getTime() - this.lastKeyHit > 300) {
          this.refetchTriggered = false
          this.searchfilter.patient = this.searchtextfilter
        } else {
          this.triggerRefetch()
        }
      }, 350)
    },
  },
  apollo: {
    transaction: {
      query: gql`
        query GetTransaction($transactionid: String!) {
          transaction(_id: $transactionid) {
            _id
            finapiId
            state
            bankBookingDate
            amount
            unassignedamount
            counterpartName
            details {
              purpose
              counterpartBankName
              counterpartIban
              counterpartBic
            }
            invoices {
              _id
              invoicenr
              amount
              balance
              firstname
              lastname
              patientfirstname
              patientlastname
              billedAt
              transactions {
                _id
                finapiId
              }
            }
            proposals {
              _id
              invoicenr
              amount
              balance
              firstname
              lastname
              patientfirstname
              patientlastname
              billedAt
              transactions {
                _id
                finapiId
              }
            }
          }
        }
      `,
      variables() {
        return {
          transactionid: this.transactionid,
        }
      },
      fetchPolicy: 'network-only',
    },
    node_invoices: {
      query: gql`
        query Invoices(
          $financial_states: [String]
          $filter: String
          $from: String
          $to: String
          $limit: Int
          $node: String!
        ) {
          node_invoices(
            searchTerm: $filter
            state: $financial_states
            from: $from
            to: $to
            limit: $limit
            node: $node
          ) {
            _id
            invoicenr
            amount
            balance
            firstname
            lastname
            patientfirstname
            patientlastname
            billedAt
            financial_state
            transactions {
              _id
              finapiId
            }
          }
        }
      `,
      variables() {
        return {
          // TODO include NODE
          to: moment(parseInt(this.transaction.bankBookingDate))
            .endOf('day')
            .toDate(),
          limit: 100,
          filter: this.searchfilter.patient,
          financial_states: this.searchfilter.financial_states,
          node: this.selectedNode,
        }
      },
      skip() {
        return true
      },
      fetchPolicy: 'network-only',
    },
  },
}
</script>

<style scoped>
.loadingspinnerwrapper {
  height: 100%;
  text-align: center;
  width: 100%;
}

.searchfield_wrapper {
  flex-grow: 1;
  padding: 5px;
  text-align: end;
}

.searchfield {
  background-color: white;
  border: 1px solid #dedede;
  border-radius: 18px;
  color: black;
  font-size: 16px;
  height: 36px;
  outline: 0;
  padding: 9px 12px;
}

.moreitemshint {
  min-height: 3rem;
  padding: 20px;
  text-align: center;
  width: 100%;
}
</style>
