import EventBus from '@/event-bus'
import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminBoard from './components/AdminBoard.vue'
import Login from './components/Login'
import TransactionDetails from './components/Transactions/TransactionDetails'
import {store} from './store'
import Dashboard from './views/Dashboard.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Dashboard,
    },
    {
        path: '/administration',
        name: 'administration',
        component: () =>
            import(
                /* webpackChunkName: "administration" */ './views/administration/Administration'
                ),
        children: [
            {
                path: 'contract',
                name: 'administration.contract',
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ './views/administration/ContractView'
                        ),
            },
        ],
    },
    {
        path: '/profile',
        name: 'profile',
        component: () =>
            import(/* webpackChunkName: "profile" */ './views/profile/Profile'),
        children: [
            {
                path: 'mydata',
                name: 'profile.mydata',
                component: () =>
                    import(
                        /* webpackChunkName: "profile" */ './components/Settings/UserSettings'
                        ),
            },
            {
                path: 'security',
                name: 'profile.security',
                component: () =>
                    import(
                        /* webpackChunkName: "profile" */ './components/Settings/UserSecurity'
                        ),
            },
            {
                path: 'reminders',
                name: 'profile.reminders',
                component: () =>
                    import(
                        /* webpackChunkName: "profile" */ './components/Settings/UserReminders'
                        ),
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            public: true,
            layout: 'pure-layout',
        },
    },
    {
        path: '/newpasswordrequired',
        name: 'newpasswordrequired',
        component: () =>
            import(
                /* webpackChunkName: "password" */ './components/Settings/NewPassword'
                ),
    },
    {
        path: '/invoices/:state',
        name: 'invoices',
        component: () =>
            import(/* webpackChunkName: "invoices" */ './views/Invoices.vue'),
    },
    {
        path: '/registration',
        name: 'registration',
        component: () =>
            import(
                /* webpackChunkName: "register" */ './views/registration/Registration'
                ),
        meta: {
            public: true,
            layout: 'blank',
        },
        children: [
            {
                path: '/register',
                name: 'register',
                component: () =>
                    import(
                        /* webpackChunkName: "register" */ './views/registration/Register'
                        ),
                meta: {
                    public: true,
                    layout: 'blank',
                },
            },
            {
                path: '/register_confirmation',
                name: 'register_confirmation',
                component: () =>
                    import(
                        /* webpackChunkName: "register" */ './views/registration/RegisterConfirmation'
                        ),
                meta: {
                    public: true,
                    layout: 'blank',
                },
                beforeEnter(to, from, next) {
                    if (from.name !== 'register') {
                        next({name: 'register'})
                    }
                    next()
                },
            },
            {
                path: '/confirm',
                name: 'confirmation',
                component: () =>
                    import(
                        /* webpackChunkName: "confirmation" */ './views/registration/Confirmation'
                        ),
                meta: {
                    public: true,
                    layout: 'blank',
                },
            },
        ],
    },
    {
        path: '/forgot_password',
        name: 'forgot_password',
        component: () =>
            import(/* webpackChunkName: "register" */ './views/ForgotPassword'),
        meta: {
            public: true,
        },
    },
    {
        path: '/billings',
        name: 'billings',
        component: () =>
            import(/* webpackChunkName: "billings" */ './views/Billings.vue'),
        children: [
            {
                path: 'pending',
                name: 'billings.pending',
                component: () =>
                    import(
                        /* webpackChunkName: "billings" */ './views/PendingBillings.vue'
                        ),
            },
            {
                path: 'sent',
                name: 'billings.sent',
                component: () =>
                    import(/* webpackChunkName: "billings" */ './views/SentBillings.vue'),
            },
        ],
    },
    {
        path: '/settings',
        name: 'settings',
        component: () =>
            import(/* webpackChunkName: "settings" */ './views/Settings'),
        children: [
            {
                path: 'node',
                name: 'settings.node',
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ './views/Settings/NodeSettings'
                        ),
            },
            {
                path: 'epost',
                name: 'settings.epost',
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ './views/Settings/NodeEpostSettings'
                        ),
            },

            {
                path: 'bank',
                name: 'settings.bank',
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ './views/Settings/NodeBankSettings'
                        ),
            },
            {
                path: 'pad_file',
                name: 'settings.pad_file',
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ './views/Settings/NodePadFileSettings'
                        ),
            },
            {
                path: 'payment_import',
                name: 'settings.payment_import',
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ './views/Settings/NodePaymentImportSettings'
                        ),
            },

            {
                path: 'layout',
                name: 'settings.layout',
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ './views/Settings/NodeLetterLayoutSettings'
                        ),
            },
            {
                path: 'reminder',
                name: 'settings.reminder',
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ './components/Settings/ReminderSettings.vue'
                        ),
            },
            {
                path: 'user',
                name: 'settings.user',
                component: () =>
                    import(
                        /* webpackChunkName: "settings" */ './components/Settings/UserSettings.vue'
                        ),
            },
        ],
    },
    {
        path: '/support',
        name: 'support',
        component: () =>
            import(
                /* webpackChunkName: "support" */ './views/support/SupportLanding'
                ),
        children: [
            {
                path: 'videos',
                name: 'support.videos',
                component: () =>
                    import(
                        /* webpackChunkName: "support" */ './views/support/SupportVideos'
                        ),
            },
            {
                path: 'downloads',
                name: 'support.downloads',
                component: () =>
                    import(
                        /* webpackChunkName: "support" */ './views/support/SupportDownloads'
                        ),
            },
        ],
    },
    {
        path: '/transactions/:transaction_state',
        name: 'transactions',
        component: () =>
            import(/* webpackChunkName: "transactions" */ './views/Transactions.vue'),
    },
    {
        path: '/transactions/transaction/:transactionid/',
        name: 'transactiondetails',
        component: TransactionDetails,
        props: true,
    },
    {
        path: '/documents',
        name: 'documents',
        component: () =>
            import(/* webpackChunkName: "attachments" */ './views/Documents.vue'),
    },
    {
        path: '/services-management',
        name: 'services-management',
        component: () =>
            import(
                /* webpackChunkName: "service-management" */ './views/ServicesManagement.vue'
                ),
        children: [
            {
                path: 'material-costs',
                name: 'services-management.material-costs',
                component: () =>
                    import(
                        /* webpackChunkName: "service-management" */ './views/ServicesManagement/MaterialCosts.vue'
                        ),
            },
        ],
    },
    {
        path: '/adminboard',
        name: 'adminboard',
        component: AdminBoard,
        beforeEnter: (to, from, next) => {
            if (store.state.roles.board_admin) {
                next()
            } else {
                next('/')
            }
        },
    },
]

const router = new VueRouter({
    routes,
    mode: 'history',
    scrollBehavior() {
        return {top: 0}
    },
})

router.beforeEach(async (to, from, next) => {
    const currentQuery = to.query
    if (window.innerWidth <= 1024) {
        await store.dispatch('hideMenu')
    }
    EventBus.$emit('resetScrollPosition')
    if (to.meta && to.meta.layout) {
        store.commit('SET_LAYOUT', to.meta.layout)
    }
    const isAuthenticated = store.state.loggedIn
    if (!isAuthenticated && to.meta && !to.meta['public']) {
        await store.dispatch('refresh', to)
    }
    if (!isAuthenticated || currentQuery.node || (!store.getters.availableNodes || store.getters.availableNodes.length === 1)) {
        next()
    } else {
        next({...to, query: {...to.query, ...(store.getters.selectedNode && {node: store.getters.selectedNode})}})
    }
})

export default router
