import { store } from './store'

export function VueErrorHandler(error, vm, info) {
  console.warn('VueError', error, vm, info)
}

export function ApolloErrorHandler(error) {
  console.warn('ApolloError', error)
}

export function NetworkErrorHandler(error) {
  if (error.code === 401 || error.statusCode === 401) {
    window.location.href = '/login'
  } else {
    // Do not show error message for 400 errors as this will be handled by the graphql error handler
    if (error.statusCode === 400) {
      return
    }
    store.commit('SET_SNACKBAR_TEXT', {
      text: error.message,
      type: 'error',
    })
  }
}

const ERROR_MAP = {
  DOCUMENT_ATTACHED_TO_INVOICES: (error) =>
    `Dieses Dokument ist den Rechnungen ${error.extensions.invoices} zugeordnet und kann nicht gelöscht werden.`,
  DIRECTORY_CONTAINS_ATTACHED_DOCUMENTS: (error) =>
    `Dieser Ordner enthält Dokumente, welche den Rechnungen ${error.extensions.invoices} zugeordnet sind und kann nicht gelöscht werden.`,
  NOT_AUTHORIZED: 'Sie sind nicht berechtigt, diese Aktion auszuführen.',
  NOT_AUTHENTICATED: 'Sie sind nicht angemeldet.',
}

function getErrorMessage(error) {
  if (error.extensions && error.extensions.code) {
    const message = ERROR_MAP[error.extensions.code]
    if (typeof message === 'function') {
      return message(error)
    }
    if (message) {
      return message
    }
    return error.message
      ? `${error.extensions.code}: ${error.message}`
      : 'Unbekannter Fehler'
  }
  return error.message
}

export function GraphQLErrorHandler(error) {
  if (error.length) {
    error.forEach((err) => {
      store.commit('SET_SNACKBAR_TEXT', {
        text: getErrorMessage(err),
        type: 'error',
      })
    })
  }
}
