<template>
  <div>
    <div class="lg:tw-grid lg:tw-grid-cols-minContent-auto lg:tw-h-screen">
      <div
        v-on-clickaway="away"
        :class="{
          'tw-translate-x-0': $store.getters.menu,
          '-tw-translate-x-full': !$store.getters.menu,
        }"
        class="tw-fixed lg:tw-relative tw-w-[280px] tw-z-50 tw-h-screen lg:tw-translate-x-0 tw-transition-all lg:tw-transition-none tw-duration-500 tw-ease-in-out tw-will-change-transform">
        <div
          class="tw-h-full tw-bg-ci-gray-75 tw-pt-32 lg:tw-pt-24 tw-border-r lg:tw-border-r-ci-gray-150">
          <Navigation />
          <SimulationBanner />
        </div>
      </div>
      <div
        class="lg:tw-grid-areas-main lg:tw-grid-rows-main lg:tw-grid lg:tw-h-screen">
        <Header></Header>
        <router-view></router-view>
      </div>
    </div>
    <ScrollToTop />
    <v-snackbar
      v-model="snackbar"
      :color="snackStyle.color"
      :vertical="snackbarLink"
      timeout="5000"
      top>
      <div class="snackbar">
        <v-icon class="tw-mr-4" left>{{ snackStyle.icon }}</v-icon>
        <div class="tw-text-base">
          <b>{{ snackbarText }}</b>
        </div>
      </div>
      <template v-if="snackbarLink" v-slot:action="{ attrs }">
        <v-btn :to="snackbarLink" text v-bind="attrs" @click="snackbar = false">
          {{ snackbarLinkTitle }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      :value="
        $store.state.finapiurl &&
        $store.state.wfStatus !== 'COMPLETED' &&
        $store.state.wfStatus !== 'ABORTED'
      "
      persistent>
      <finapi-webform :token="$store.state.finapiurl"></finapi-webform>
    </v-dialog>
    <ConfirmationDialog v-model="finapiDialog"></ConfirmationDialog>
    <CompanyNotifcationDialog />
  </div>
</template>
<script>
import CompanyNotifcationDialog from '@/components/Dialogs/CompanyNotifcationDialog.vue'
import ConfirmationDialog from '@/components/helper/ConfirmationDialog'
import ScrollToTop from '@/components/ScrollToTop'
import SimulationBanner from '@/components/Simulation/SimulationBanner.vue'
import NOTIFICATIONS from '@/gql/notifications.graphql'
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import FinapiWebform from '../components/FinapiWebform'
import Header from '../components/Header'
import Navigation from '../components/Navigation'
import FILE_ERROR from '../gql/file_error.subscription.graphql'
import FILE_PROCESSED from '../gql/file_processed.subscription.graphql'
import NEW_NOTIFICATION from '../gql/new_notification.subscription.graphql'

export default {
  components: {
    SimulationBanner,
    CompanyNotifcationDialog,
    ConfirmationDialog,
    Navigation,
    Header,
    FinapiWebform,
    ScrollToTop,
  },
  mixins: [clickaway],
  methods: {
    away: function (event) {
      if (
        event.target.classList.contains('v-btn__content') ||
        window.innerWidth > 1024
      ) {
        return
      }
      this.$store.dispatch('hideMenu')
    },
  },
  apollo: {
    notifications: {
      query: NOTIFICATIONS,
      variables: function () {
        return {
          node: this.selectedNode
        }
      },
      pollInterval: 1000 * 60 * 60,
      subscribeToMore: {
        document: NEW_NOTIFICATION,
        skip: function () {
          return this.skipNotificationSubscription
        },
        updateQuery: (previousQueryResult, { subscriptionData }) => {
          return {
            notifications: [
              ...previousQueryResult.notifications,
              subscriptionData.data.newNotification,
            ],
          }
        },
      },
      result: function ({ data }) {
        let vm = this
        const finapi = data.notifications.some((n) => {
          return n.notification_type === 'FINAPI'
        })
        if (finapi && this.$route.name !== 'newpasswordrequired') {
          vm.finapiDialog.show = true
        }
      },
    },
    $subscribe: {
      fileProcessed: {
        query: FILE_PROCESSED,
        result({ data }) {
          this.$store.commit('SET_SNACKBAR_TEXT', {
            text: this.$tc(
              'subscriptions.upload.file',
              data.fileProcessed.invoices.length,
              {
                filename: data.fileProcessed.filename,
                count: data.fileProcessed.invoices.length,
              },
            ),
          })
        },
        error(err) {
          console.warn(err)
        },
      },
      fileError: {
        query: FILE_ERROR,
        result({ data }) {
          this.$store.commit('SET_SNACKBAR_TEXT', {
            text: this.$t(
              `subscriptions.upload.file_error.${data.fileError.errorCode}`,
              { filename: data.fileError.file.filename },
            ),
            type: 'error',
          })
        },
        error(err) {
          console.warn(err)
        },
      },
    },
  },
  computed: {
    ...mapGetters([
      'error',
      'selectedNode',
      'snackbar',
      'snackbarText',
      'snackbarType',
      'snackbarLink',
      'snackbarLinkTitle',
      'skipNotificationSubscription',
    ]),
    snackStyle() {
      switch (this.snackbarType) {
        case 'error':
          return {
            color: 'error',
            icon: 'mdi-alert-octagon',
          }
        case 'info':
          return {
            color: 'info',
            icon: 'mdi-information',
          }
        default:
          return {
            color: '#00A82D',
            icon: 'mdi-checkbox-marked-circle',
          }
      }
    },
    snackbar: {
      get() {
        return this.$store.state.snackbar
      },
      set(value) {
        this.$store.commit('SET_SNACKBAR', value)
      },
    },
  },
  data() {
    return {
      finapiDialog: {
        show: false,
        text: this.$t('notifications.finapi.warning'),
        icon: 'mdi-alert',
        onDeny: () => {},
        onConfirm: () => {
          let newRoute = this.$router.match({ name: 'settings.payment_import' })
          if (this.$router.currentRoute.fullPath !== newRoute.fullPath) {
            this.$router.push({ name: 'settings.payment_import' })
          }
        },
      },
    }
  },
}
</script>
<style>
.snackbar {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: row;
  flex-wrap: nowrap;
}

.v-badge.userbell .v-badge__badge.orange {
  margin-bottom: -10px;
  margin-left: -15px;
}

.v-dialog.transaction-details:not(.v-dialog--fullscreen) {
  background: white;
  height: 70vh;
}
</style>
