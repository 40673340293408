<template>
  <v-text-field v-async-validate="$attrs.asyncValidate"
                v-mask="$attrs.mask"
                :label="generatedLabel"
                color="#00A82D"
                data-test-forms-input-text
                dense
                outlined
                v-bind="$attrs"
                v-on="$listeners" />
</template>

<script>
import { VueMaskDirective } from 'v-mask'

export default {
  name: 'InputText',
  inheritAttrs: false,
  directives: {
    mask: VueMaskDirective,
  },
  computed: {
    generatedLabel() {
      if (!this.$attrs.label) {
        return null
      }
      return `${this.$attrs.label}${(this.$attrs.required !== undefined) ? '*' : ''}`
    },
  },
}
</script>
