<template>
  <v-select :label="generatedLabel"
            color="green"
            dense
            flat
            outlined
            v-bind="$attrs"
            v-on="$listeners" />
</template>

<script>
export default {
  name: 'InputSelect',
  inheritAttrs: false,
  computed: {
    generatedLabel() {
      if (!this.$attrs.label) {
        return null
      }
      return `${this.$attrs.label}${(this.$attrs.required !== undefined) ? '*' : ''}`
    },
  },
}
</script>
