<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :bottom="position === 'bottom'"
    :close-on-content-click="false"
    :left="position === 'left'"
    :right="position === 'right'"
    :top="position === 'top'"
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <div :class="classes">
        <v-text-field
          v-model="formattedDate"
          :hide-details="hideDetails"
          :label="generatedLabel"
          :prepend-icon="prependIcon"
          :prepend-inner-icon="prependInnerIcon"
          :required="required"
          :rules="rules"
          class="tw-w-full"
          color="#00A82D"
          data-test-forms-input-date
          dense
          outlined
          readonly
          v-bind="attrs"
          v-on="on" />
      </div>
    </template>
    <v-date-picker
      v-model="date"
      :active-picker.sync="activePicker"
      :max="max"
      :min="min"
      color="#00A82D"
      locale="de-de"
      no-title
      @input="updateValue" />
  </v-menu>
</template>

<script>
import moment from 'moment'

export default {
  name: 'InputDate',
  inehritAttrs: false,
  props: {
    value: {
      type: undefined,
    },
    label: {
      type: String,
      default: null,
    },
    prependInnerIcon: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    initialPicker: {
      type: String,
      default: 'DATE',
    },
    classes: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    min: {
      type: String,
      default: '1900-01-01',
    },
    max: {
      type: String,
      default: null,
    },
    displayFormat: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    outputFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    inputFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    position: {
      type: String,
      default: 'bottom',
    },
  },
  data() {
    return {
      menu: false,
      date: this.value
        ? moment(this.value, this.inputFormat).format('YYYY-MM-DD')
        : null,
      activePicker: null,
    }
  },
  computed: {
    formattedDate() {
      if (!this.date) {
        return ''
      }
      return moment(this.date, 'YYYY-MM-DD').format(this.displayFormat)
    },
    generatedLabel() {
      if (!this.label) {
        return null
      }
      return `${this.label}${this.required ? '*' : ''}`
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = this.initialPicker))
    },
    value: {
      handler: function (val) {
        this.date = moment(val, this.inputFormat).format('YYYY-MM-DD')
      },
      deep: true,
    },
  },
  methods: {
    updateValue() {
      this.menu = false
      if (!this.date) {
        this.$emit('input', null)
        return
      }
      if (this.outputFormat === 'ISO') {
        this.$emit('input', moment(this.date, 'YYYY-MM-DD').toISOString())
        return
      }
      this.$emit(
        'input',
        moment(this.date, 'YYYY-MM-DD').format(this.outputFormat),
      )
    },
  },
}
</script>
