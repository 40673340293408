<template>
  <v-dialog v-model="isDialogVisible" content-class="tw-max-w-lg">
    <DialogContainer>
      <v-form ref="formRef" v-model="form.valid" @submit.prevent="submit()">
        <DialogHeader @close="closeDialog">PAD-Datei hochladen</DialogHeader>
        <DialogBody
          :class="{
            'tw-bg-ci-gray-100': dragover,
          }"
          class="tw-p-4 lg:tw-p-8"
          @drop.prevent="handleDrop"
          @dragover.prevent="dragover = true"
          @dragenter.prevent="dragover = true"
          @dragleave.prevent="dragover = false">
          <div>
            <v-file-input
              v-model="form.file"
              :read-only="loading"
              :rules="[rules.required]"
              color="green"
              dense
              label="Wählen Sie eine PAD-Datei aus"
              outlined
              persistent-hint
              prepend-icon="mdi-paperclip"
              show-size />
          </div>
        </DialogBody>
        <DialogFooter>
          <DialogActions>
            <SecondaryButton @click.prevent="closeDialog">
              Abbrechen
            </SecondaryButton>
            <PrimaryButton :loading="loading" type="submit">
              PAD-Datei hochladen
            </PrimaryButton>
          </DialogActions>
        </DialogFooter>
      </v-form>
    </DialogContainer>
  </v-dialog>
</template>

<script>
import BillingsQuery from '@/gql/queries/Billings.graphql'
import UploadFileMutation from '@/gql/upload_file.graphql'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UploadPadFileDialog',
  data() {
    return {
      dragover: false,
      loading: false,
      form: {
        valid: false,
        path: null,
        file: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      rules: 'rules',
      selectedNode: 'selectedNode',
      isDialogVisible: 'billings/isUploadBillingDialogVisible',
    }),
  },
  props: {
    parentBlock: {
      type: Object,
      default: null,
    },
  },
  methods: {
    ...mapActions({
      setExpandedBilling: 'billings/setExpandedBilling',
      closeDialog: 'billings/closeUploadBillingDialog',
    }),
    handleDrop(event) {
      if (!event.dataTransfer.files.length) {
        return
      }
      this.dragover = false
      if (this.form.file) {
        this.form.file = null
      }
      this.form.file = event.dataTransfer.files.item(0)
    },
    async submit() {
      if (!this.form.valid) {
        return
      }
      this.loading = true
      const { data } = await this.$apollo.mutate({
        mutation: UploadFileMutation,
        errorPolicy: 'all',
        variables: {
          node: this.selectedNode,
          file: this.form.file,
        },
        context: {
          hasUpload: true,
        },
        refetchQueries: [
          {
            query: BillingsQuery,
            variables: {
              node: this.selectedNode,
              state: 'processed',
              limit: 20,
            },
          },
        ],
      })
      this.loading = false
      if (!data?.uploadFile?.checksum) {
        return
      }
      await this.closeDialog()
      this.$route.name !== 'billings.pending' &&
        (await this.$router.push({ name: 'billings.pending' }))
      this.$refs.formRef.reset()
      await this.setExpandedBilling(data.uploadFile._id)
    },
  },
}
</script>
