<template>
  <th class="tw-uppercase tw-font-semibold tw-text-xs lg:tw-sticky lg:tw-top-0 lg:tw-z-2">
    <span :class="{ 'tw-cursor-pointer': sortable }"
          class="tw-relative tw-whitespace-nowrap tw-z-20"
          @click.prevent="sort">
      <slot>
        {{ header.text }}
      </slot>
      <Transition name="asc">
        <v-icon v-if="sortIsActive && currentSortDirection === 'asc'"
                class="tw-absolute tw-ml-1 tw-text-inherit"
                small>
          mdi-sort-{{ iconType }}-ascending
        </v-icon>
      </Transition>
      <Transition name="desc">
        <v-icon v-if="sortIsActive && currentSortDirection === 'desc'"
                class="tw-absolute tw-ml-1 tw-text-inherit"
                small>
          mdi-sort-{{ iconType }}-descending
        </v-icon>
      </Transition>
    </span>
  </th>
</template>

<script>
export default {
  name: 'DataTableHeader',
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sortable: function () {
      if (this.header?.value === 'actions') {
        return false
      }
      return this.header.sortable !== false;

    },
    currentSortKey: function () {
      return this.$parent.currentSortKey
    },
    currentSortDirection: function () {
      return this.$parent.currentSortDirection
    },
    sortIsActive: function () {
      if (!this.sortable) {
        return false
      }
      return this.currentSortKey === this.header.value
    },
    iconType: function () {
      if (this.header.type) {
        switch (this.header.type) {
          case 'date':
            return 'calendar'
          case 'number':
            return 'numeric'
          case 'float':
            return 'numeric'
          default:
            return 'alphabetical'
        }
      }
      return 'alphabetical'
    },
  },
  methods: {
    sort() {
      if (!this.sortable) {
        return
      }
      if (this.$parent.currentSortKey === this.header.value && this.$parent.currentSortDirection === 'desc') {
        this.$parent.currentSortKey = null
        this.$parent.currentSortDirection = null
        this.$parent.currentSortType = null
        return
      } else if (this.$parent.currentSortKey === this.header.value) {
        this.$parent.currentSortDirection = this.$parent.currentSortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.$parent.currentSortDirection = 'asc'
      }
      this.$parent.currentSortKey = this.header.value
      this.$parent.currentSortType = this.header.type || 'string'
    },
  },
}
</script>

<style>
.snds-data-table--with-actions th:nth-last-child(2) {
  padding-right: 0 !important;
}

.asc-enter-active {
  animation: asc-in .3s;
}

.desc-enter-active {
  animation: desc-in .3s;
}

.asc-leave-active,
.desc-leave-active {
  animation: out .3s;
}

@keyframes asc-in {
  0% {
    transform: translateY(-8px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes desc-in {
  0% {
    transform: translateY(8px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes out {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(.5);
    opacity: 0;
  }
}
</style>
