var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.roles.board_simulation)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.showBanner = false)),expression:"() => (showBanner = false)"}],staticClass:"tw-absolute tw-left-3 tw-bottom-0 tw-w-64"},[_c('div',{staticClass:"tw-rounded-t-md tw-bg-white tw-shadow-lg tw-px-4 tw-pt-4 tw-pb-6 tw-relative tw-transition-all tw-duration-200",class:{
      'tw-mb-8': _vm.showBanner,
      '-tw-mb-[100%] tw-pointer-events-none': !_vm.showBanner,
    }},[_c('p',{staticClass:"tw-m-0 tw-text-sm"},[_vm._v(" Hier können Sie Aktionen durchführen, um den Echtbetrieb der Software zu simulieren. ")]),_c('div',{staticClass:"tw-space-y-4 tw-mt-5"},[_c('v-btn',{staticClass:"tw-whitespace-nowrap tw-text-left tw-text-white",attrs:{"block":"","color":"grey","depressed":"","elevation":"0","small":""},on:{"click":function($event){$event.preventDefault();return _vm.showResetSimulationDatabaseDialog()}}},[_vm._v(" Zurücksetzen der Daten ")]),_c('v-btn',{staticClass:"tw-whitespace-nowrap tw-text-left tw-text-white",attrs:{"block":"","color":"grey","depressed":"","elevation":"0","small":""},on:{"click":function($event){$event.preventDefault();return _vm.showUploadPadFileDialog()}}},[_vm._v(" Upload einer PAD-Datei ")])],1)]),_c('button',{staticClass:"tw-w-full hover:tw-bg-ci-green/90 tw-absolute tw-bottom-0 tw-transition-transform tw-duration-300 tw-h-8 tw-bg-ci-green tw-text-white tw-flex tw-items-center tw-justify-center tw-space-x-4",class:{
      'tw-rounded-t-md': !_vm.showBanner,
    },on:{"click":function($event){$event.preventDefault();_vm.showBanner = !_vm.showBanner}}},[_c('span',[_vm._v("Demo - Simulation")]),_c('v-icon',{staticClass:"tw-text-current",class:{
        'tw-rotate-180': _vm.showBanner,
      }},[_vm._v(" mdi-chevron-up ")])],1),_c('v-dialog',{attrs:{"content-class":"tw-max-w-xl","persistent":""},model:{value:(_vm.resetSimulationDatabaseDialog),callback:function ($$v) {_vm.resetSimulationDatabaseDialog=$$v},expression:"resetSimulationDatabaseDialog"}},[(_vm.resetSimulationDatabaseDialog)?_c('ResetSimulationDatabaseDialog',{on:{"close":function($event){_vm.resetSimulationDatabaseDialog = false}}}):_vm._e()],1),_c('v-dialog',{attrs:{"content-class":"tw-max-w-xl","persistent":""},model:{value:(_vm.uploadPadFileDialog),callback:function ($$v) {_vm.uploadPadFileDialog=$$v},expression:"uploadPadFileDialog"}},[(_vm.uploadPadFileDialog)?_c('UploadPadFileDialog',{on:{"close":function($event){_vm.uploadPadFileDialog = false}}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }