export default {
  pad_upload(state) {
    return state.roles && state.roles.pad && state.roles.pad.upload
  },
  demo(state) {
    return state.demo
  },
  epost_active(state) {
    return state.epost_active
  },
  roles(state) {
    return state.roles
  },
  layout(state) {
    return state.layout
  },
  client_name(state) {
    return state.client_name
  },
  clientType(state) {
    return state.clientType
  },
  client_avatar_name(state) {
    let names = state.client_name.split(' ')
    return `${names[0].charAt(0).toUpperCase()}${names[names.length - 1]
      .charAt(0)
      .toUpperCase()}`
  },
  availableNodes(state) {
    return state.availableNodes
  },
  selectedNode(state) {
    return state.selectedNode
  },
  nodeCustomerID(state) {
    return state.nodeCustomerID
  },
  nodeId(state) {
    return state.nodeId
  },
  is_app() {
    return config.VUE_APP_DEVICE === 'app'
  },
  error(state) {
    return state.error
  },
  title(state) {
    return `${state.titlePrefix} ${state.title} ${state.subtitle}`
  },
  snackbar(state) {
    return state.snackbar
  },
  snackbarText(state) {
    return state.snackbarText
  },
  snackbarType(state) {
    return state.snackbarType
  },
  snackbarLink(state) {
    return state.snackbarLink
  },
  snackbarLinkTitle(state) {
    return state.snackbarLinkTitle
  },
  rules(state) {
    return state.rules
  },
  settingsFormValid(state) {
    return state.settingsFormValid
  },
  reminderactive(state) {
    return state.reminderactive
  },
  menu(state) {
    return state.menu
  },
  sticky(state) {
    return state.sticky
  },
  skipNotificationSubscription(state) {
    return state.skipNotificationSubscription
  },
}
