import './main.css'
import { VueErrorHandler } from '@/error-handler'
import axios from 'axios'
import Vue from 'vue'
import App from './App.vue'
import filters from './filters'
import globalComponents from './globalComponents'
import i18n from './i18n'
import vmask from './plugins/vmask'
import vuetify from './plugins/vuetify'
import router from './router'
import { store } from './store'
import { createProvider } from './vue-apollo'

axios.defaults.withCredentials = true
Vue.config.productionTip = false
Vue.config.errorHandler = VueErrorHandler

new Vue({
  globalComponents,
  router,
  store,
  filters,
  vuetify,
  vmask,
  apolloProvider: createProvider(),
  i18n,
  render: (h) => h(App),
}).$mount('#app')
