<template>
  <div class="lg:tw-grid lg:tw-grid-cols-minContent-auto lg:tw-h-screen">
    <div class="tw-bg-[url('/src/assets/svg/header-pattern.svg')] tw-bg-cover tw-bg-bottom lg:tw-bg-ci-green tw-h-[200px] tw-flex tw-items-center lg:tw-items-start lg:tw-h-full lg:tw-w-[430px]">
      <div class="tw-px-4 lg:tw-px-0 lg:tw-mt-20 lg:tw-mx-20">
        <Logo class="tw-text-white"/>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-justify-center">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue'

export default {
  name: 'Pure',
  components: {
    Logo,
  },
}
</script>
