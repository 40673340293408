<template>
  <header
    ref="header"
    class="tw-h-14 lg:tw-h-[70px] tw-z-40 tw-relative snds-header">
    <div
      :class="{
        'tw-fixed tw-shadow-lg': $store.getters.sticky,
        '-tw-translate-y-full': !$store.getters.sticky && isOutOfView,
        'tw-translate-y-0': !isOutOfView,
      }"
      class="tw-h-14 lg:tw-h-[70px] tw-w-full lg:tw-shadow-none tw-flex tw-flex-row tw-justify-between tw-items-center tw-pl-2 tw-pr-4 lg:tw-px-12 tw-bg-white tw-will-change-auto tw-transition-all tw-top-0">
      <div>
        <v-btn
          class="lg:tw-hidden elevation-0"
          icon
          @click="$store.dispatch('showMenu')">
          <v-icon class="tw-pointer-events-none">mdi-equal</v-icon>
        </v-btn>
      </div>
      <div
        class="tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-x-2">
        <div class="lg:tw-mr-3">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <div class="tw-flex tw-flex-row tw-items-center lg:tw-mr-3">
                <v-btn class="tw-pl-4 tw-pr-3" color="green" outlined v-on="on">
                  <v-icon class="tw-mr-2.5">mdi-file-document-plus</v-icon>
                  <span class="tw-hidden sm:tw-block">Neue Abrechnung</span>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </div>
            </template>
            <div class="tw-bg-white tw-w-64">
              <v-list dense>
                <v-list-item @click="showUploadPadFileDialog">
                  <v-list-item-title class="tw-text-base tw-text-ci-gray-600">
                    Abrechnungsdatei hochladen
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="createEmptyFile">
                  <v-list-item-title class="tw-text-base tw-text-ci-gray-600">
                    Manuelle Abrechnung anlegen
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </div>
          </v-menu>
        </div>
        <div class="tw-hidden lg:tw-flex">
          <v-divider class="tw-h-7 lg:tw-min-h-0 lg:tw-self-center" vertical />
        </div>
        <Notification />
        <div class="tw-hidden lg:tw-flex">
          <v-divider class="tw-h-7 lg:tw-min-h-0 lg:tw-self-center" vertical />
        </div>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <div class="tw-flex tw-flex-row tw-items-center">
              <v-btn
                :ripple="false"
                class="tw-hidden lg:tw-inline-block lg:tw-px-2 elevation-0 lg:tw-rounded-full lg:tw-text-sm"
                plain
                v-on="on">
                {{ client_name }}
                <v-icon small>mdi-chevron-down</v-icon>
              </v-btn>
              <v-btn
                class="elevation-0 tw-bg-ci-gray-300 tw-text-white tw-h-10 lg:tw-h-9 tw-w-10 lg:tw-w-9 tw-flex tw-justify-center tw-items-center tw-text-sm"
                icon
                v-on="on">
                {{ client_avatar_name }}
              </v-btn>
            </div>
          </template>
          <div class="tw-p-8 tw-bg-white tw-w-64">
            <v-list class="tw-py-0 tw-divide-ci-gray-65 tw-divide-y" dense>
              <v-list-item
                :to="{ name: 'settings.node', query: { node: selectedNode } }"
                class="tw-pl-0">
                <v-list-item-icon class="tw-mr-2.5">
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="tw-text-base tw-text-ci-gray-600">
                  {{ $t('usermenu.settings') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'profile.mydata', query: { node: selectedNode } }"
                class="tw-pl-0 v-list-item-active:tw-bg-white">
                <v-list-item-icon class="tw-mr-2.5">
                  <v-icon>mdi-account-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="tw-text-base tw-text-ci-gray-600">
                  {{ $t('usermenu.profile') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{
                  name: 'administration.contract',
                  query: { node: selectedNode },
                }"
                class="tw-pl-0">
                <v-list-item-icon class="tw-mr-2.5">
                  <v-icon>mdi-shield-account-variant-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="tw-text-base tw-text-ci-gray-600">
                  {{ $t('usermenu.administration') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :to="{ name: 'support.videos', query: { node: selectedNode } }"
                class="tw-pl-0">
                <v-list-item-icon class="tw-mr-2.5">
                  <v-icon>mdi-lifebuoy</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="tw-text-base tw-text-ci-gray-600">
                  {{ $t('usermenu.support') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="tw-pl-0" @click="logout()">
                <v-list-item-icon class="tw-mr-2.5">
                  <v-icon>mdi-power</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="tw-text-base tw-text-ci-gray-600">
                  {{ $t('usermenu.logout') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </div>
        </v-menu>
      </div>
    </div>
    <UploadPadFileDialog />
  </header>
</template>
<script>
import UploadPadFileDialog from '@/components/Billings/UploadPadFileDialog.vue'
import CreateEmptyFileMutation from '@/gql/mutations/createEmptyFile.graphql'
import BillingsQuery from '@/gql/queries/Billings.graphql'
import { throttle } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import Notification from './Notification'

const SCROLL_THROTTLE = 150

export default {
  components: {
    UploadPadFileDialog,
    Notification,
  },
  methods: {
    ...mapActions({
      showUploadPadFileDialog: 'billings/showUploadBillingDialog',
      startEmptyBillingCreationProcess:
        'billings/startEmptyBillingCreationProcess',
    }),
    async createEmptyFile() {
      this.createEmptyFileIsLoading = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CreateEmptyFileMutation,
          variables: {
            node: this.selectedNode,
          },
          refetchQueries: [
            {
              query: BillingsQuery,
              variables: {
                node: this.selectedNode,
                limit: 20,
                state: 'processed',
              },
            },
          ],
          awaitRefetchQueries: true,
        })
        await this.setExpandedBilling(data.createEmptyFile._id)
        await this.setSnackbarText({
          text: 'Manuelle Abrechnung wurde angelegt.',
        })
        this.$route.name !== 'billings.pending' &&
          (await this.$router.push({ name: 'billings.pending' }))
        await this.showEditInvoiceDialog(data.createEmptyFile.invoices[0])
        await this.startEmptyBillingCreationProcess(data.createEmptyFile._id)
      } catch (error) {
        // DO NOTHING
      } finally {
        this.createEmptyFileIsLoading = false
      }
    },

    async logout() {
      await this.$store.dispatch('logout') // important to be first. resetStore triggers new request and may set the cookied again
      let client = this.$apollo.getClient()
      client.resetStore()
      if (client.wsClient) {
        //TODO restart websockets
        //restartWebsockets(client.wsClient)
      }
    },

    handleIsSticky: throttle(function () {
      this.isOutOfView = window.scrollY > this.$refs.header.clientHeight

      if (
        window.scrollY > this.$refs.header.clientHeight &&
        this.previousScrollY > window.scrollY &&
        !this.sticky
      ) {
        this.$store.dispatch('setSticky', true)
      } else if (this.previousScrollY < window.scrollY && this.sticky) {
        this.$store.dispatch('setSticky', false)
      }

      this.previousScrollY = window.scrollY
    }, SCROLL_THROTTLE),
    ...mapActions({
      setSnackbarText: 'setSnackbarText',
      setExpandedBilling: 'billings/setExpandedBilling',
      showEditInvoiceDialog: 'invoice/showEditInvoiceDialog',
      showUploadPadFileDialog: 'billings/showUploadBillingDialog',
    }),
  },
  computed: {
    ...mapGetters([
      'client_name',
      'client_avatar_name',
      'sticky',
      'selectedNode',
    ]),
  },
  data() {
    return {
      notifications: true,
      notifications_panel: true,
      previousScrollY: 0,
      isOutOfView: false,
      uploadPadFileDialog: false,
      createEmptyFileIsLoading: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleIsSticky)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleIsSticky)
  },
}
</script>

<style>
.snds-header {
  -webkit-app-region: drag;
  -webkit-user-select: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  @apply tw-opacity-0;
}

.theme--light.v-list-item--active::before {
  background-color: transparent;
}

.v-list .v-list-item--active {
  @apply tw-text-ci-green-500;
}
</style>
