<script>
  import {WebFormBuilder} from '@finapi/embeddable-web-form/finapi-embeddable-web-form'

  export default {
    props: ['token'],
    name: 'finapi-webform',
    computed: {
      wfStatus() {
        return this.$store.state.wfStatus
      },
      finapiToken() {
        return this.token ? this.token.split('/').pop() : null
      }
    },
    watch: {
      token: function (newVal) {
        if (newVal && window.webFormData) {
          console.log('changed token')
//          let element = document.getElementById('webform-wrapper')
/*          let form = document.createElement('web-form-element') // muss so gemacht werden, da vue es im Template ablehnt
          element.appendChild(form)*/
          var event = new CustomEvent('re-enable',
            {
              detail:
                {
                  webFormToken: this.finapiToken
                }
            });
          window.dispatchEvent(event)
          this.watchStatus()
        }
      }
    },
    mounted() {
      this.makeForm()
    },
    methods: {
      makeForm() {
        let element = document.getElementById('webform-wrapper')
        let form = document.createElement('web-form-element') // muss so gemacht werden, da vue es im Template ablehnt
        element.appendChild(form)
        let wf = new WebFormBuilder(this.finapiToken, config.VUE_APP_FINAPI_URL).withOwnerDocument(document).build()
        try {
          wf.startWebFormComponents()
        } catch (err) {
          console.warn(err)
        }
        this.watchStatus()
      },
      watchStatus () {
        let vm = this
        let timer = setInterval(function () {
          let i = document.getElementById('web-form-status')
          if (i) {
            vm.$store.commit('SET_WF_STATUS', i.value)
            if (i.value == 'COMPLETED' || i.value == 'ABORTED') {
              clearInterval(timer)
              vm.closeWebForm()
            }
          }
        }, 500)
      },
      closeWebForm() {
        this.$store.commit("SET_FINAPI_URL", null)
        let webFormContainer = document.querySelector('web-form-element')
        if (webFormContainer) {
          webFormContainer.dispatchEvent(new Event('dismount'))
        }
      }
    },
    beforeDestroy() {
      clearInterval(this.wfStatusTimer)
      this.closeWebForm()
    }
  }
</script>
<template>
    <div id="webform-wrapper">
        <input id="web-form-status" hidden readonly/>
        <script type="text/json" id="external-web-form-css">
    {
        "webFormBody": {
            "maxWidth": "450px"
        },
        "webFormBtnSubmit": {
            "backgroundColor": "#00A82D"
        }
    }
        </script>
    </div>
</template>
<style>
    #web-form-status {
        display: none;
    }
</style>
