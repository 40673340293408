<template>
  <v-menu absolute content-class="tw-top-14" max-height="600px" z-index="500">
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="notifications && !notifications.length"
        class="elevation-0 tw-h-10 tw-w-10 disabled:tw-opacity-50"
        icon
        v-on="on">
        <v-badge
          :content="
            notifications ? Object.keys(groupedNotifications).length : null
          "
          :value="notifications && notifications.length"
          bordered
          color="orange"
          offset-x="10"
          offset-y="10">
          <v-icon class="tw-cursor-pointer tw-text-ci-gray-300">
            mdi-bell
          </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-list v-if="notifications && notifications.length" two-line width="400">
      <v-divider v-if="notifications"></v-divider>
      <template v-if="groupedNotifications.FINAPI">
        <v-list-item three-line @click="processNotification('finapi')">
          <v-list-item-content>
            <v-list-item-title>
              <b>{{ $t('notifications.finapi.title') }}</b>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('notifications.finapi.sub-title') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-if="groupedNotifications.INVOICE">
        <v-list-item three-line @click="processNotification('invoice')">
          <v-list-item-content>
            <v-list-item-title>
              <b>{{ $t('notifications.invoice.title') }}</b>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('notifications.finapi.sub-title') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template v-if="groupedNotifications.TRANSACTION">
        <v-list-item three-line @click="processNotification('transaction')">
          <v-list-item-content>
            <v-list-item-title>
              <b>{{ $t('notifications.transaction.title') }}</b>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ $t('notifications.transaction.sub-title') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      notifications: [],
    }
  },
  apollo: {
    notifications: {
      query: require('@/gql/notifications.graphql'),
      skip: function () {
        return this.selectedNode === ''
      },
      variables: function () {
        return {
          node: this.selectedNode,
        }
      },
    },
  },
  computed: {
    ...mapGetters(['selectedNode']),
    groupedNotifications() {
      return this.notifications
        ? this.notifications.reduce((c, v) => {
            c[v.notification_type] = v
            return c
          }, {})
        : {}
    },
  },
  methods: {
    processNotification(target) {
      let urlTarget = 'home'
      let params = {}
      switch (target) {
        case 'finapi':
          urlTarget = 'settings.payment_import'
          break
        case 'transaction':
          urlTarget = 'transactions'
          params['transaction_state'] = 'unassigned'
          break
        case 'invoice':
          urlTarget = 'invoices'
          params['state'] = 'overdue'
          break
      }
      if (target !== 'finapi') {
        this.$apollo.mutate({
          mutation: require('@/gql/mark_notifications_read.graphql'),
          variables: {
            type: target.toUpperCase(),
            node: this.selectedNode,
          },
          refetchQueries: [
            {
              query: require('@/gql/notifications.graphql'),
              variables: {
                node: this.selectedNode,
              },
            },
          ],
        })
      }
      let newRoute = this.$router.match({
        name: urlTarget,
        params: params,
      })
      if (this.$router.currentRoute.fullPath !== newRoute.fullPath) {
        this.$router.push({
          name: urlTarget,
          params: params,
        })
      }
    },
  },
}
</script>
