import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VuetifyAsyncValidation from 'vuetify-async-validation'

Vue.use(Vuetify)
Vue.use(VuetifyAsyncValidation)

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        green: '#00A82D',
      },
      dark: {
        green: '#00A82D',
      },
    },
  },
})
