export const currencyformatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
})

export const decimalformatter = new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const dateoptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
}
export const dateformatter = new Intl.DateTimeFormat('de-DE', dateoptions)

const monthoptions = {
  month: 'long',
}
export const monthformatter = new Intl.DateTimeFormat('de-DE', monthoptions)

const timeoptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
}
export const timeformatter = new Intl.DateTimeFormat('de-DE', timeoptions)
