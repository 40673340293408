<template>
  <div
    class="lg:tw-grid lg:tw-grid-in-page lg:tw-grid-areas-page lg:tw-grid-rows-page">
    <div class="lg:tw-grid-in-header">
      <slot name="header" />
    </div>
    <div
      ref="content"
      class="lg:tw-overflow-x-auto tw-pb-16 lg:tw-grid-in-content lg:tw-px-12 tw-max-w-full">
      <slot name="content" />
    </div>
    <div
      class="tw-fixed tw-bottom-0 tw-left-0 tw-right-0 lg:tw-relative lg:tw-bottom-auto lg:tw-left-auto lg:tw-right-auto lg:tw-grid-in-footer lg:tw-bg-white tw-flex tw-items-center tw-justify-center tw-border-t tw-border-ci-gray-150 tw-bg-white tw-h-8 lg:tw-h-auto">
      <slot name="footerRight" />
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import { throttle } from 'lodash'

const SCROLL_THROTTLE = 100
const RESIZE_THROTTLE = 100
const EMIT_DELAY = 1000
const PROPORTIONAL_DISTANCE = 3

export default {
  name: 'SanadeusPage',
  data() {
    return {
      distance: 0,
      previousY: 0,
      lastEmit: null,
    }
  },
  methods: {
    emitEndOfContent(event) {
      if (this.lastEmit >= Date.now() - EMIT_DELAY) {
        return
      }
      this.lastEmit = Date.now()
      EventBus.$emit('endOfContent', event)
    },
    getRemaining(totalScrollHeight, scrolledUntilNow) {
      return totalScrollHeight - scrolledUntilNow
    },
    getDistance() {
      return Math.round(this.$refs.content.clientHeight / 3)
    },
    windowScrollHandler: throttle(function (event) {
      const scrollTop = window.scrollY
      const scrollDown = scrollTop > this.previousY
      if (!scrollDown) {
        this.previousY = window.scrollY
        return
      }
      const totalScrollHeight = document.documentElement.scrollHeight
      const scrolledUntilNow = scrollTop + document.documentElement.clientHeight
      if (totalScrollHeight < scrolledUntilNow) {
        return
      }
      const remaining = this.getRemaining(totalScrollHeight, scrolledUntilNow)
      if (remaining < this.distance) {
        this.emitEndOfContent(event)
      }
      this.previousY = window.scrollY
    }, SCROLL_THROTTLE),
    contentScrollHandler: throttle(function (event) {
      const scrollTop = this.$refs.content.scrollTop
      const scrollDown = scrollTop > this.previousY
      if (!scrollDown) {
        this.previousY = this.$refs.content.scrollTop
        return
      }
      const totalScrollHeight = this.$refs.content.scrollHeight
      const scrolledUntilNow = scrollTop + this.$refs.content.clientHeight
      if (totalScrollHeight < scrolledUntilNow) {
        return
      }
      const remaining = this.getRemaining(totalScrollHeight, scrolledUntilNow)
      if (remaining < this.distance) {
        this.emitEndOfContent(event)
      }
    }, SCROLL_THROTTLE),
    determineScrollEventListener: throttle(function () {
      window.removeEventListener('scroll', this.windowScrollHandler)
      this.$refs.content.removeEventListener(
        'scroll',
        this.contentScrollHandler,
      )
      if (document.documentElement.scrollWidth > 1024) {
        this.distance = Math.round(
          this.$refs.content.clientHeight / PROPORTIONAL_DISTANCE,
        )
        this.$refs.content.addEventListener('scroll', this.contentScrollHandler)
      } else {
        this.distance = Math.round(window.innerHeight / PROPORTIONAL_DISTANCE)
        window.addEventListener('scroll', this.windowScrollHandler)
      }
    }, RESIZE_THROTTLE),
  },
  mounted() {
    this.determineScrollEventListener()
    window.addEventListener('resize', this.determineScrollEventListener)
    EventBus.$on('resetScrollPosition', () => {
      setTimeout(() => {
        this.previousY = 0
        if (this.$refs.content) {
          this.$refs.content.scroll({
            top: 0,
            left: 0,
          })
        }
      }, 100)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.determineScrollEventListener)
    window.removeEventListener('scroll', this.windowScrollHandler)
    this.$refs.content.removeEventListener('scroll', this.contentScrollHandler)
  },
}
</script>

<style scoped>
.sanadeus-page-footer {
  border-top: 1px solid #d0d0d0;
  bottom: 0;
  display: grid;
  grid-template-columns: 280px 1fr;
  height: 30px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.sanadeus-page-footer__right {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}
</style>
