<template>
  <span>
    {{ time }}
  </span>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Time',
  props: {
    value: {
      type: String,
      required: true,
    },
    inputFormat: {
      type: String,
      required: true,
    },
    outputFormat: {
      type: String,
      default: 'HH:mm',
    },
    suffix: {
      type: String,
      default: 'Uhr',
    },
  },
  computed: {
    time() {
      return `${moment(this.value, this.inputFormat).format(this.outputFormat)} ${this.suffix}`
    },
  },
}
</script>
