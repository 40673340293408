export default {
  SET_DEMO(state, demo) {
    state.demo = demo
  },
  SET_MENU(state, menu) {
    state.menu = menu
  },
  SET_STICKY(state, sticky) {
    state.sticky = sticky
  },
  SET_EPOST_ACTIVE(state, active) {
    state.epost_active = active
  },
  SET_LAYOUT(state, layout) {
    state.layout = layout
  },
  SET_LOGIN_ERROR(state, value) {
    state.loginError = value
  },
  SET_LOGIN(state, value) {
    state.loggedIn = value
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_ROLES(state, roles) {
    state.roles = roles
  },
  SET_CLIENT_NAME(state, name) {
    state.client_name = name
  },
  SET_CLIENT_TYPE(state, value) {
    state.clientType = value
  },
  SET_SNACKBAR(state, value) {
    state.snackbar = value
    if (!value) {
      state.snackbarText = null
    }
  },
  SET_SNACKBAR_TEXT(state, options) {
    state.snackbarText = options.text
    state.snackbarType = options.type || 'success'
    state.snackbarLink = options.link || null
    state.snackbarLinkTitle = options.linkTitle || null
    state.snackbar = true
  },
  SET_SETTINGS_FORM_VALID(state, value) {
    state.settingsFormValid = value
  },
  SET_FINAPI_URL(state, value) {
    state.finapiurl = value
  },
  SET_WF_STATUS(state, value) {
    state.wfStatus = value
  },
  SET_REMINDER_ACTIVE(state, value) {
    state.reminderactive = value
  },
  SET_NEW_PASSWORD_REQUIRED(state, value) {
    state.newpasswordrequired = value
  },
  SET_SKIP_NOTIFICATION_SUBSCRIPTION(state, value) {
    state.skipNotificationSubscription = value
  },
  SET_NODE_CUSTOMER_ID(state, value) {
    state.nodeCustomerID = value
  },
  SET_NODE_ID(state, value) {
    state.nodeId = value
  },
  SET_AVAILABLE_NODES(state, value) {
    state.availableNodes = value
  },
  SET_SELECTED_NODE(state, value) {
    state.selectedNode = value
  }
}
