<template>
  <SanadeusPage>
    <template slot="header">
      <SanadeusPageHeader>
        <template slot="title">
          Admin Board
        </template>
      </SanadeusPageHeader>
    </template>
    <template slot="content">
      <ElectronContent class="pa-3 red">
        <h2>Mich sieht man nur in Electron</h2>
      </ElectronContent>
      <div v-if="showConnectionDialog === true">
        <v-form @submit.prevent="connect()">
          <v-container>
            <v-row>
              <span>TESTIBAN: DE33700800850002222222</span>
            </v-row>
            <v-row>
              <v-text-field v-model="iban"
                            placeholder="IBAN"></v-text-field>
              <v-btn type="submit">Verbinden</v-btn>
            </v-row>
          </v-container>
        </v-form>
      </div>
      <div v-else-if="this.node.finapi.state==='disconnected' || !this.node.finapi.state">
        <div v-if="node.finapi.errorcode">Es ist ein Fehler mit der Finapi aufgetreten: {{ node.finapi.errorcode }}: {{ node.finapi.errormessage }}</div>
        <p>Um diese Funktion zu nutzen, müssen sie Ihr Bankkonto verknüpfen.</p>
        <v-btn v-on:click="startconnect">Bankkonto jetzt verknüpfen</v-btn>
      </div>
      <div v-else-if="node.finapi.state==='updating'">
        <div class="center">
          <p>Kontodaten werden aktualisiert</p>
          <v-progress-circular
              :size="70"
              :width="7"
              color="purple"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-btn v-on:click="disconnect()">Aktualisierung unterbrechen und Bankverbindung trennen</v-btn>
      </div>
      <div v-else-if="node.finapi.state==='connected'">
        <div class='btns'>
          <v-btn v-on:click="disconnect()">Bankverbindung trennen</v-btn>
          <v-btn v-on:click="update()">Bankverbindung aktualisieren</v-btn>
          <v-btn v-on:click="createFakeInvoices()">Erstelle Dummy-Rechnungen</v-btn>
          <v-btn v-on:click="importFakes()">Importiere Dummy-Überweisungen</v-btn>
          <v-btn v-on:click="autoassign()">Automatische Zuweisung</v-btn>
          <!--
          <v-btn v-if="process.env.NODE_ENV == 'development'" v-on:click="deleteDb()">Datenbank löschen</v-btn>
          -->
          <v-btn v-on:click="deleteDb()">Datenbank löschen</v-btn>
        </div>
      </div>
      <div v-else-if="node.finapi.state==='waitforwebform'">
        <div v-if="node.finapi.errorcode">Es ist ein Fehler mit der Finapi aufgetreten: {{ node.finapi.errorcode }}: {{ node.finapi.errormessage }}</div>
        <div>Warte auf Eingabe Webformular</div>
        <v-btn v-on:click="disconnect()">Aktualisierung unterbrechen und Bankverbindung trennen</v-btn>
      </div>
      <div v-else>
        <div>Finapi Status ist: {{ node.finapi.state }}</div>
        <div v-if="node.finapi.errorcode">Es ist ein Fehler mit der Finapi aufgetreten: {{ node.finapi.errorcode }}: {{ node.finapi.errormessage }}</div>
        <v-btn v-on:click="disconnect()">Bankverbindung trennen</v-btn>
        <v-btn v-on:click="update()">Bankverbindung aktualisieren</v-btn>
      </div>

      <!--      <div style="position:absolute;">
              <div>{{ mydates.from }} - {{ mydates.to }}</div>
              <DatePicker v-model="mydates"></DatePicker>
            </div>-->


      <!--  <div style="margin-top: 70px; width:397px;heigth:80px;">
          <div>
            Border
          </div>
          <StatusLine :portions="portions" stroke="2"></StatusLine>
        </div>
        <div style="width:397px;">
          <div>
            Border
          </div>
          <StatusLine :portions="portions2" stroke="2"></StatusLine>
        </div>

        <div style="margin-top: 80px;">
          <v-btn v-on:click="provokeError()">Fehler erzeugen</v-btn>
        </div>
        <div style="margin-top: 80px;">
          <div>{{ mymonth.month }}</div>
          <MonthPicker v-model="mymonth"></MonthPicker>
        </div>
        <v-row>
          <v-col cols="12">
            <template v-for="invoice in giro_invoices">
              <v-card :key="invoice._id">
                <v-card-title><img :key="invoice._id" :src="invoice.girocode">
                </v-card-title>
                <v-card-text>
                  <p>Betrag: {{invoice.amount}}</p>
                  <p>Rechnungsnummer: {{invoice.invoicenr}}</p>
                </v-card-text>
              </v-card>
            </template>
          </v-col>
        </v-row>-->
    </template>


  </SanadeusPage>
</template>

<script>
//import DatePicker from './helper/DatePicker'
/*import MonthPicker from './helper/MonthPicker'
import StatusLine from './helper/StatusLine'*/
import gql from 'graphql-tag'
import moment from 'moment'
import { dateformatter } from './helper/formatter'
import ElectronContent from './ElectronContent'
import SanadeusPage from './SanadeusPage/SanadeusPage'

import SanadeusPageHeader from './SanadeusPage/SanadeusPageHeader'
import {mapGetters} from "vuex";

export default {
  components: {
    SanadeusPage,
    SanadeusPageHeader,
    ElectronContent,
    //DatePicker
    //MonthPicker,
    //StatusLine,
  },
  data: function () {
    return {
      node: {
        finapi: {
          state: 'disconnected',
        },
      },
      node_invoices: [],
      iban: '',
      portions: [
        {
          color: 'green',
          proportion: 1,
        },
        {
          color: 'blue',
          proportion: 2,
        },
      ],
      portions2: [
        {
          color: 'green',
          proportion: 1000,
        },
        {
          color: 'blue',
          proportion: 2000,
        },
      ],
      showConnectionDialog: false,
      mydates: {
        from: moment().subtract(30, 'days'),
        to: moment(),
      },
      mymonth: moment().startOf('month').toDate(),
    }
  },
  methods: {
    startconnect: function () {
      console.log('show connection dialog')
      this.showConnectionDialog = true
    },
    connect: function () {
      this.showConnectionDialog = false
      this.iban = this.iban.replaceAll(' ', '')
      window.fetch(`api/finapi/bankconnection/${this.selectedNode}/connect?iban=` + this.iban, {
        credentials: 'include',
      })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            if (data.redirect) {
              console.log('set redirecting')
              this.webformid = data.webformid
              window.open(data.redirect)
            } else {
              console.log('set connected')
            }
          })
    },
    update: function () {
      window.fetch(`api/finapi/bankconnection/${this.selectedNode}/update`, {
        method: 'POST',
        credentials: 'include',
      })
          .then((response) => {
            return response.json()
          })
          .then((data) => {
            if (data.redirect) {
              console.log('set redirecting')
              this.webformid = data.webformid
              window.open(data.redirect)
            } else {
              console.log('set connected')
            }
          })
    },
    importFakes: function () {
      window.fetch('api/finapi/importfakes', {
        method: 'POST',
        credentials: 'include',
      })
          .then((response) => {
            return response.json()
          })
          .finally(() => {
            this.$apollo.queries.node.refetch()
          })
    },
    createFakeInvoices: function () {
      window.fetch('api/finapi/createfakeinvoices', {
        method: 'POST',
        credentials: 'include',
      })
          .then((response) => {
            return response.json()
          })
          .finally(() => {
            this.$apollo.queries.node.refetch()
          })
    },
    deleteDb: function () {
      window.fetch('api/finapi/deletedb', {
        method: 'POST',
        credentials: 'include',
      })
          .then((response) => {
            return response.json()
          })
          .finally(() => {
            this.$apollo.queries.node.refetch()
          })
    },
    autoassign: function () {
      window.fetch('api/finapi/autoassign', {
        method: 'POST',
        credentials: 'include',
      })
          .then((response) => {
            return response.json()
          })
          .finally(() => {
            this.$apollo.queries.node.refetch()
          })
    },
    disconnect: function () {
      window.fetch(`api/finapi/bankconnection/${this.selectedNode}/disconnect`, {
        credentials: 'include',
      })
          .then((response) => {
            return response.json()
          })
          .finally(() => {
            this.$apollo.queries.node.refetch()
          })
    },
    provokeError: function () {
      window.fetch('api/test/provokeerror', {
        method: 'POST',
        credentials: 'include',
      })
          .then(() => {
            return
          })
    },
    formatDate: function (datestring) {
      return dateformatter.format(new Date(datestring))
    },
  },
  computed: {
    ...mapGetters(['selectedNode']),
    giro_invoices() {
      return this.node_invoices.filter((i) => {
        return i.girocode
      })
    },
  },
  apollo: {
    node_invoices: {
      query: gql`query { node_invoices {_id amount invoicenr girocode state }}`,
      fetchPolicy: 'network-only',
    },
    node: {
      query: gql`query { node {_id name finapi {state errorcode errormessage}}}`,
      fetchPolicy: 'network-only',
      pollInterval: 1500,
      // TODO subscribeToMore
    },
  },
}
</script>

<style scoped>
.flex-right {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
}

.btns {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.btns button {
  margin: 10px 25px;
}

.center {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.transactiontable {
  list-style: none;
  margin: 0;
  padding: 0;
}

.datepickerbutton {
  background-color: #00A82D;
  border-radius: 17px;
  color: white;
  font-size: 1rem;
  height: 34px;
  margin-bottom: 20px;
  width: 255px;
}

.datepicker {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: fixed;
}

.datepreselectionbox {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 200px;
}


.datepicker .v-picker {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.datepreselectionbox button {
  height: 30px;
  margin: 4px;
}

.datepreselectionbox__header {
  background-color: #1976D2;
  border-top-right-radius: 4px;
  display: absolute;
  height: 88px;
  width: 100%;
}

li {
  border-bottom: 1px solid #EDEDED;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.header {
  height: 32px;
  padding: 7px 0;
  text-transform: uppercase;
}

.transactionrow {
  min-height: 81px;
  overflow: hidden;
  padding: 24px 0 13px 0;
}

.column1 {
  font-weight: bold;
  height: 44px;
  overflow: hidden;
  width: 155px;
}

.column2 {
  height: 44px;
  overflow: hidden;
  width: 420px;
}

.column3 {
  flex-grow: 1;
  height: 44px;
  overflow: hidden;
  text-align: center;
}

.column4 {
  height: 44px;
  overflow: hidden;
  text-align: right;
  width: 140px;
}

.counterpartname {
}

.purpose {
  color: #919090;
  font-size: 0.9rem;
  line-height: 21px;
}

.statuslinewrapperdiv {
  border: 1px solid orange;
  height: 4px;
  width: 300px;
}
</style>
