<template>
  <DataTable
    :headers="headers"
    :items="items"
    :loading="loading"
    :skeleton-items="3"
    class="tw-mt-5 -tw-mx-4 lg:tw-mx-0">
    <template #header(createdAt)="{ header }">
      <DataTableHeader
        :header="header"
        class="tw-text-left tw-pr-4 lg:tw-pr-8 tw-bg-white tw-text-ci-gray-400 tw-border-b-2 tw-border-b-ci-gray-150 tw-pb-2 tw-pl-4 lg:tw-pl-0" />
    </template>
    <template #header(invoicesCount)="{ header }">
      <DataTableHeader
        :header="header"
        class="tw-text-left tw-pr-4 lg:tw-pr-8 tw-bg-white tw-text-ci-gray-400 tw-border-b-2 tw-border-b-ci-gray-150 tw-pb-2" />
    </template>
    <template #header(total)="{ header }">
      <DataTableHeader
        :header="header"
        class="tw-text-right tw-bg-white tw-text-ci-gray-400 tw-border-b-2 tw-border-b-ci-gray-150 tw-pb-2 last:tw-pr-4 lg:last:tw-pr-0"></DataTableHeader>
    </template>
    <template #cell(createdAt)="{ item }">
      <DataTableColumn
        class="tw-border-b-2 tw-border-b-ci-gray-150 tw-pt-6 tw-pb-2 tw-pl-4 tw-pr-4 lg:tw-pr-8 lg:tw-pl-0 tw-w-1/4">
        <Date
          :value="item.createdAt"
          class="tw-block tw-font-bold"
          inputFormat="DD.MM.YYYY, hh:mm:ss" />
        <Time
          :value="item.createdAt"
          class="tw-block tw-text-sm tw-text-ci-gray-400 tw-pt-1"
          inputFormat="DD.MM.YYYY, hh:mm:ss" />
      </DataTableColumn>
    </template>
    <template #skeleton(createdAt)>
      <DataTableColumn
        class="tw-border-b-2 tw-border-b-ci-gray-150 tw-pt-6 tw-pb-2 tw-pl-4 lg:tw-pl-0 tw-w-1/4">
        <v-skeleton-loader class="tw-h-[21px] tw-w-24" type="text" />
        <v-skeleton-loader class="tw-h-5 tw-w-16 tw-mt-1" type="text" />
      </DataTableColumn>
    </template>
    <template #cell(invoicesCount)="{ item }">
      <DataTableColumn
        class="tw-border-b-2 tw-pr-4 lg:tw-pr-8 tw-pt-6.5 tw-pb-2.5 tw-border-b-ci-gray-150 tw-align-top">
        {{ item.invoicesCount }}
      </DataTableColumn>
    </template>
    <template #skeleton(invoicesCount)>
      <DataTableColumn
        class="tw-border-b-2 tw-border-b-ci-gray-150 tw-pt-6.5 tw-pb-2.5 tw-align-top">
        <v-skeleton-loader class="tw-h-5 tw-w-12" type="text" />
      </DataTableColumn>
    </template>
    <template #cell(total)="{ value }">
      <DataTableColumn
        class="tw-text-right tw-border-b-2 tw-border-b-ci-gray-150 last:tw-pr-4 lg:last:tw-pr-0">
        <Amount :value="value" class="tw-font-extrabold" />
      </DataTableColumn>
    </template>
    <template #skeleton(total)>
      <DataTableColumn
        class="tw-text-right tw-border-b-2 tw-border-b-ci-gray-150 last:tw-pr-4 lg:last:tw-pr-0">
        <v-skeleton-loader class="tw-w-20 tw-ml-auto" height="23" type="text" />
      </DataTableColumn>
    </template>
    <template v-if="showStatusLine" #statusline="{ item }">
      <StatusLine
        :loading="loading"
        :portions="getStatusLinePortions(item)"
        class="-tw-mt-0.5"
        stroke="2" />
    </template>
  </DataTable>
</template>

<script>
export default {
  name: 'FilesDashboard',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showStatusLine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'createdAt',
          text: 'Erstellt am',
          sortable: false,
        },
        {
          value: 'invoicesCount',
          text: 'Rechnungen',
          sortable: false,
        },
        {
          value: 'total',
          text: 'Gesamt',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    getStatusLinePortions(item) {
      return [
        {
          color: '#EDEDED',
          proportion: item.statistics.unbilled,
        },
        {
          color: '#00A82D',
          proportion: item.statistics.paid,
        },
        {
          color: '#82C8E2',
          proportion: item.statistics.open,
        },
        {
          color: '#EF8305',
          proportion: item.statistics.due,
        },
        {
          color: '#F82C06',
          proportion: item.statistics.overdue,
        },
      ]
    },
  },
}
</script>
