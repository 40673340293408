<template>
  <button
    v-ripple
    class="tw-py-2 tw-pl-3 tw-pr-2 tw-text-left tw-flex tw-items-center tw-h-10 tw-border tw-border-ci-green disabled:hover:tw-border-ci-green hover:tw-bg-ci-green hover:bg-opacity-50 tw-border-solid tw-rounded tw-transition-colors tw-outline-0 disabled:tw-opacity-50"
    v-bind="$attrs"
    v-on="$listeners">
    <span class="tw-text-ci-gray-700 tw-font-bold">
      <slot></slot>
    </span>
    <v-icon v-if="appendIcon" class="tw-ml-auto tw-text-ci-gray-700">
      {{ appendIcon }}
    </v-icon>
  </button>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    appendIcon: {
      type: String,
      default: null,
    },
  },
}
</script>
