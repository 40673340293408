<template>
  <th class="lg:tw-sticky lg:tw-top-0 lg:tw-z-1">
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: 'DataTableHeaderDummy',
}
</script>
