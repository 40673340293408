<template>
  <v-app id="sanadeus">
    <component v-bind:is="layout" v-if="validView"></component>
    <div v-else>Sie können diese Ansicht nicht aufrufen.</div>
  </v-app>
</template>
<script>
import EventBus from '@/event-bus'
import { throttle } from 'lodash'
import { mapGetters, mapState } from 'vuex'
import Base from './layouts/Base'
import Blank from './layouts/Blank'
import Pure from './layouts/Pure'

export default {
  computed: {
    ...mapState(['layout']),
    ...mapGetters(['title', 'is_app']),
    validView() {
      return (
        (this.is_app &&
          navigator.userAgent.toLowerCase().includes('sanadeus')) ||
        !this.is_app
      )
    },
  },
  methods: {
    emitResizeEvent: throttle(function (event) {
      EventBus.$emit('resize', event)
    }, 400),
  },
  created() {
    document.title = this.title
  },
  mounted() {
    window.addEventListener('resize', this.emitResizeEvent)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.emitResizeEvent)
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    'base-layout': Base,
    'blank': Blank,
    'pure-layout': Pure,
  },
}
</script>

<style>
* {
  letter-spacing: normal !important;
}

.clickable {
  cursor: pointer;
}

.sana-btn {
  background-color: #00a82d !important;
  font-size: 14px;
  text-transform: none !important;
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/nunito-sans-v5-latin_latin-ext-regular.eot');
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-regular.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-regular.woff2')
      format('woff2'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-regular.woff')
      format('woff'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-regular.ttf')
      format('truetype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-regular.svg#NunitoSans')
      format('svg');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/nunito-sans-v5-latin_latin-ext-italic.eot');
  src: local('Nunito Sans Italic'), local('NunitoSans-Italic'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-italic.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-italic.woff2')
      format('woff2'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-italic.woff')
      format('woff'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-italic.ttf')
      format('truetype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-italic.svg#NunitoSans')
      format('svg');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/nunito-sans-v5-latin-600.eot');
  src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
    url('assets/fonts/nunito-sans-v5-latin-600.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/nunito-sans-v5-latin-600.woff2') format('woff2'),
    url('assets/fonts/nunito-sans-v5-latin-600.woff') format('woff'),
    url('assets/fonts/nunito-sans-v5-latin-600.ttf') format('truetype'),
    url('assets/fonts/nunito-sans-v5-latin-600.svg#NunitoSans') format('svg');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 600;
  src: url('assets/fonts/nunito-sans-v5-latin-600italic.eot');
  src: local('Nunito Sans SemiBold Italic'), local('NunitoSans-SemiBoldItalic'),
    url('assets/fonts/nunito-sans-v5-latin-600italic.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/nunito-sans-v5-latin-600italic.woff2') format('woff2'),
    url('assets/fonts/nunito-sans-v5-latin-600italic.woff') format('woff'),
    url('assets/fonts/nunito-sans-v5-latin-600italic.ttf') format('truetype'),
    url('assets/fonts/nunito-sans-v5-latin-600italic.svg#NunitoSans')
      format('svg');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/nunito-sans-v5-latin_latin-ext-700.eot');
  src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700.woff2') format('woff2'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700.woff') format('woff'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700.ttf')
      format('truetype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700.svg#NunitoSans')
      format('svg');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts/nunito-sans-v5-latin_latin-ext-800.eot');
  src: local('Nunito Sans ExtraBold'), local('NunitoSans-ExtraBold'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800.woff2') format('woff2'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800.woff') format('woff'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800.ttf')
      format('truetype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800.svg#NunitoSans')
      format('svg');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/nunito-sans-v5-latin_latin-ext-700italic.eot');
  src: local('Nunito Sans Bold Italic'), local('NunitoSans-BoldItalic'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700italic.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700italic.woff2')
      format('woff2'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700italic.woff')
      format('woff'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700italic.ttf')
      format('truetype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-700italic.svg#NunitoSans')
      format('svg');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 800;
  src: url('assets/fonts/nunito-sans-v5-latin_latin-ext-800italic.eot');
  src: local('Nunito Sans ExtraBold Italic'),
    local('NunitoSans-ExtraBoldItalic'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800italic.eot?#iefix')
      format('embedded-opentype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800italic.woff2')
      format('woff2'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800italic.woff')
      format('woff'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800italic.ttf')
      format('truetype'),
    url('assets/fonts/nunito-sans-v5-latin_latin-ext-800italic.svg#NunitoSans')
      format('svg');
}

body,
html,
.v-application {
  font: normal 16px/21px 'Nunito Sans', Helvetica, sans-serif !important;
}
</style>
