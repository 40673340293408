<script>
import demoDataNodesQuery from '@/gql/queries/demoDataNodes.graphql'
import { defineComponent, ref } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'UploadPadFileDialog',
  setup() {
    const form = ref({
      referencedDemoDataNode: null,
      numberOfInvoices: 20,
    })
    const loading = ref(false)
    return {
      form,
      loading,
    }
  },
  apollo: {
    demoDataNodes: {
      query: demoDataNodesQuery,
    },
  },
  computed: {
    ...mapGetters(['selectedNode']),
    availableDemoDataNodes() {
      return this.demoDataNodes.map((node) => ({
        text: node.name,
        value: node._id,
      }))
    },
  },
  data: () => ({
    demoDataNodes: [],
  }),
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    async uploadPadFile() {
      try {
        this.loading = true
        await window.fetch(
          `${config.VUE_APP_API_ENDPOINT}/api/simulation/uploadfile`,
          {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              node: this.selectedNode,
              referencedDemoDataNode: this.form.referencedDemoDataNode,
              numberOfInvoices: this.form.numberOfInvoices,
            }),
          },
        )
        await this.$apolloProvider.defaultClient.resetStore()
        this.loading = false
        this.closeDialog()
        if (this.$route.name !== 'billings.pending') {
          try {
            await this.$router.push({
              name: 'billings.pending',
              query: {
                node: this.selectedNode,
              },
            })
          } catch (e) {
            // DO NOTHING
          }
        }
      } catch (e) {
        alert(e)
        this.loading = false
      }
    },
  },
})
</script>

<template>
  <DialogContainer>
    <DialogHeader :hideCloseBtn="loading" @close="closeDialog">
      PAD-Datei hochladen
    </DialogHeader>
    <DialogBody class="tw-px-8 tw-pb-3 tw-pt-8">
      <p class="tw-m-0">Der Upload einer PAD-Datei wird simuliert.</p>
      <p class="tw-mt-6 tw-mb-0">
        Optional kann der PAD-Upload mit einer ausgewählten Datenquelle
        initialisiert werden. Wenn keine Datenquelle ausgewählt wird, werden
        Lorem-Ipsum-Daten verwendet.
      </p>
      <InputSelect
        v-model="form.referencedDemoDataNode"
        :disabled="loading"
        :items="availableDemoDataNodes"
        class="tw-mt-4"
        label="Datenquelle" />
      <InputText
        v-model="form.numberOfInvoices"
        :disabled="loading"
        class="tw-mt-4"
        label="Anzahl der Rechnungen"
        type="number" />
    </DialogBody>
    <DialogFooter>
      <DialogActions>
        <PrimaryButton :loading="loading" @click.prevent="uploadPadFile">
          PAD-Datei hochladen
        </PrimaryButton>
      </DialogActions>
    </DialogFooter>
  </DialogContainer>
</template>
