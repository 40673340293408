<template>
    <v-alert :width="width" :icon="icon" border="left" :color="color" text>
        <slot>{{message}}</slot>
    </v-alert>
</template>
<script>
    export default {
      props: {
        color: {type: String},
        icon: {type: String},
        message: {type: String},
        width: {type: String, default: "100%"},
      }
    }
</script>
