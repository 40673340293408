<template>
  <div
    class="invoicerow invoices_grid lg:tw-px-8 tw-px-4 tw-text-sm tw-py-4"
    @mouseleave="hover = false"
    @mouseover="mouseover">
    <div class="doublerow">
      <div class="bold">{{ invoice.invoicenr }}</div>
      <div class="gray">{{ invoice.billedAt }}</div>
    </div>
    <div v-if="invoice.patientfirstname">
      {{ invoice.patientfirstname }} {{ invoice.patientlastname }}
    </div>
    <div v-else>{{ invoice.firstname }} {{ invoice.lastname }}</div>
    <div>
      <button v-if="isAssigned()" class="assignbtn" @click="unassign()">
        Entfernen
      </button>
      <button v-else-if="isAssignable()" class="assignbtn" @click="assign()">
        Zuweisen
      </button>
    </div>
    <div>{{ getAssignedTransactions() }}</div>
    <div class="doublerow right">
      <div class="bold">{{ formatCurrency(invoice.amount) }}</div>
      <div>{{ formatCurrency(invoice.balance) }}</div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { currencyformatter, dateformatter } from '../helper/formatter'

export default {
  props: {
    invoice: Object,
    transaction: Object,
  },
  data: function () {
    return {
      hover: false,
    }
  },
  methods: {
    mouseover: function () {
      this.hover = true
    },
    formatDate: function (datestring) {
      return dateformatter.format(new Date(parseInt(datestring)))
    },
    formatCurrency: function (amount) {
      return currencyformatter.format(amount)
    },
    isAssignable: function () {
      return this.invoice.balance > 0 && this.transaction.unassignedamount > 0
    },
    assign: async function () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($transactionid: String!, $invoiceid: String!) {
            assignTransactionToInvoice(
              transactionid: $transactionid
              invoiceid: $invoiceid
            ) {
              transaction {
                _id
                unassignedamount
                invoices {
                  _id
                  invoicenr
                }
              }
              invoice {
                _id
                balance
                financial_state
                transactions {
                  finapiId
                  _id
                }
              }
            }
          }
        `,
        variables: {
          invoiceid: this.invoice._id,
          transactionid: this.transaction._id,
        },
      })
    },
    unassign: function () {
      this.$apollo.mutate({
        mutation: gql`
          mutation ($transactionid: String!, $invoiceid: String!) {
            unassignTransactionFromInvoice(
              transactionid: $transactionid
              invoiceid: $invoiceid
            ) {
              transaction {
                _id
                unassignedamount
                invoices {
                  _id
                  invoicenr
                }
              }
              invoice {
                _id
                balance
                transactions {
                  finapiId
                  _id
                }
              }
            }
          }
        `,
        variables: {
          invoiceid: this.invoice._id,
          transactionid: this.transaction._id,
        },
      })
    },
    getAssignedTransactions: function () {
      return this.invoice.transactions.map((t) => t.finapiId).join(', ')
    },
    isAssigned: function () {
      return this.invoice.transactions.some(
        (t) => t.finapiId === this.transaction.finapiId,
      )
    },
  },
}
</script>

<style scoped>
.right {
  text-align: right;
}
.bold {
  font-weight: bold;
}
.gray {
  color: #919090;
}
.assignbtn {
  background-color: #ededed;
  border-radius: 15px;
  color: #777;
  height: 30px;
  padding: 5px 14px;
  transition: 0.2s;
}
.assignbtn:hover {
  background-color: #00a82d;
  color: white;
  transition: 0.2s;
}
</style>
