<template>
  <div
    class="lg:tw-ml-36 tw-w-full lg:tw-max-w-sm tw-px-4 lg:tw-px-0 tw-mt-12 lg:tw-mt-0">
    <h1 class="tw-text-2xl tw-font-bold tw-text-ci-gray-700">Anmelden</h1>
    <v-form
      v-model="valid"
      class="tw-mt-6 lg:tw-mt-9"
      data-test-form
      @submit.prevent="login()">
      <div class="tw-flex tw-flex-col">
        <InputText
          v-model="loginCredentials.email"
          :label="$t('forms.login.email')"
          :rules="[rules.required, rules.email]"
          required />
        <InputPassword
          v-model="loginCredentials.password"
          :label="$t('forms.login.password')"
          :rules="[rules.required]"
          required />
      </div>
      <v-alert v-if="loginError" :value="true" type="error">
        {{ $t('forms.login.error') }}
      </v-alert>
      <div class="tw-mt-8">
        <PrimaryButton
          :disabled="!valid"
          :loading="loading"
          small
          type="submit">
          {{ $t('forms.login.login') }}
        </PrimaryButton>
      </div>
      <p class="tw-mt-9">
        {{ $t('forms.login.password_lost') }}
        <a
          class="tw-text-ci-green"
          href="mailto:support@sanadeus.de?subject=Passwort vergessen">
          {{ $t('forms.login.us') }}
        </a>
        !
      </p>
    </v-form>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
  components: {},

  computed: {
    ...mapState({
      loginError: (state) => state.loginError,
    }),
    ...mapGetters(['rules']),
    userAgent() {
      return navigator.userAgent
    },
  },
  data() {
    return {
      loading: false,
      loginCredentials: {
        email: '',
        password: '',
        target: null,
      },
      valid: false,
    }
  },
  created() {
    if (this.$route.query.email) {
      this.loginCredentials.email = this.$route.query.email
    }
  },
  methods: {
    electronFunction() {
      if (config.VUE_APP_DEVICE === 'app') {
        const { ipcRenderer } = window.electron
        ipcRenderer.invoke('testAction', 'teststring')
      }
    },
    async login() {
      try {
        this.loading = true
        this.loginCredentials.target = this.$route.query.redirect || '/'
        await this.$store.dispatch('login', this.loginCredentials)
      } catch (err) {
        console.warn(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
