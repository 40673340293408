<template>
  <th class="tw-w-10 lg:tw-sticky lg:tw-top-0 lg:tw-z-1">
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: 'DataTableHeaderActions',
  props: {
    rowIndex: {
      type: Number,
    },
  },
}
</script>
