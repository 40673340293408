<template>
  <v-dialog v-model="dialog" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" :open="showDialog" v-bind="scope" />
    </template>
    <DialogContainer>
      <DialogHeader v-if="title" @close="cancel">
        {{ title }}
      </DialogHeader>
      <DialogBody class="tw-px-8 tw-pt-7 tw-pb-5">
        {{ bodyText }}
      </DialogBody>
      <DialogFooter>
        <DialogActions>
          <SecondaryButton tabindex="0" @click.prevent="cancel">
            Abbrechen
          </SecondaryButton>
          <PrimaryButton
            :loading="loading"
            autofocus
            tabindex="0"
            @click.prevent="confirm">
            {{ primaryButtonText }}
          </PrimaryButton>
        </DialogActions>
      </DialogFooter>
    </DialogContainer>
  </v-dialog>
</template>

<script>
export default {
  emits: ['confirmed', 'cancelled'],
  props: {
    title: {
      type: String,
      default: null,
    },
    bodyText: {
      type: String,
      required: true,
    },
    primaryButtonText: {
      type: String,
      default: 'OK',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    async: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      confirmationCallback: null,
    }
  },
  methods: {
    confirm() {
      this.$emit('confirmed', () => this.closeDialog)
      if (!this.async) {
        this.closeDialog()
      }
      this.confirmationCallback && this.confirmationCallback()
    },
    cancel() {
      this.$emit('cancelled')
      this.closeDialog()
    },
    closeDialog() {
      this.dialog = false
    },
    showDialog($event, confirmationCallback = null) {
      setTimeout(() => {
        this.dialog = true
        if (confirmationCallback) {
          this.confirmationCallback = confirmationCallback
        }
      })
    },
  },
}
</script>
