<template>
  <span>
    {{ amount }}
  </span>
</template>

<script>
import { currencyformatter } from '../helper/formatter'

export default {
  name: 'Amount',
  computed: {
    amount() {
      return currencyformatter.format(Number(this.value) || 0)
    },
  },
  props: {
    value: {
      type: undefined,
      default: 0,
    },
  },
}
</script>
