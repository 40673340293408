<template>
  <span>
    {{ date }}
  </span>
</template>

<script>
import { dateformatter } from '@/components/helper/formatter'
import moment from 'moment'

export default {
  name: 'Date',
  props: {
    value: {
      type: String,
      required: true,
    },
    inputFormat: {
      type: String,
      required: false,
    },
  },
  computed: {
    date() {
      let value = this.value
      if (this.inputFormat === 'X') {
        value = parseInt(this.value / 1000)
      }
      const date = new Date(moment(value, this.inputFormat).toISOString())
      return dateformatter.format(date)
    },
  },
}
</script>
