<template>
  <SanadeusPage>
    <template #header>
      <SanadeusPageHeader>
        <template #title>Übersicht</template>
      </SanadeusPageHeader>
    </template>
    <template #content>
      <div
        class="tw-grid tw-grid-cols-1 lg:tw-grid-cols-2 tw-gap-y-10 lg:tw-gap-y-12 lg:tw-gap-x-8 tw-px-4 lg:tw-px-0 tw-mt-6 lg:tw-mt-0">
        <div class="lg:tw-col-start-1 lg:tw-row-start-1">
          <MonthPicker
            v-model="selectedmonth"
            headline-extra-classes="tw-text-xl lg:tw-text-2xl tw-font-bold tw-text-ci-gray-700" />
          <DoughnutChart
            :counter="doughnutchartdata.numberOfEntries"
            :data="doughnutchartdata.statistics"
            class="tw-mt-6" />
        </div>
        <div class="lg:tw-col-start-1 lg:tw-row-start-2">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-end">
            <h2
              class="tw-text-xl lg:tw-text-2xl tw-font-bold tw-text-ci-gray-700">
              Gesamt offen
            </h2>
            <template v-if="$apollo.queries.month_statistics.loading">
              <v-skeleton-loader
                class="tw-w-24 tw-ml-auto"
                height="23"
                type="text" />
            </template>
            <template v-else>
              <Amount :value="totalopen" class="tw-font-extrabold" />
            </template>
          </div>
          <StatusLine
            :loading="$apollo.queries.openpayments.loading"
            :portions="openportions"
            class="tw-mt-2"
            stroke="2" />
        </div>
        <div class="lg:tw-col-start-1 lg:tw-row-start-3">
          <h2
            class="tw-text-xl lg:tw-text-2xl tw-font-bold tw-text-ci-gray-700">
            Versandte Abrechnungen
          </h2>
          <div class="tw-relative">
            <FilesDashboard
              :items="sentfiles"
              :loading="$apollo.queries.sentfiles.loading"
              class="tw-pointer-events-none"
              showStatusLine />
            <router-link :to="{ name: 'billings.sent' }">
              <span class="tw-inset-0 tw-absolute" />
              <span
                class="tw-text-ci-green tw-font-bold tw-mt-5 tw-inline-block">
                Alle versandten Abrechnungen
              </span>
            </router-link>
          </div>
        </div>
        <div class="lg:tw-col-start-2 lg:tw-row-start-1">
          <h2
            class="tw-text-xl lg:tw-text-2xl tw-font-bold tw-text-ci-gray-700">
            Die letzten 12 Monate
          </h2>
          <BarChart :data="barchartdata" class="tw-mt-6 tw-h-44" />
        </div>
        <div class="lg:tw-col-start-2 lg:tw-row-start-2">
          <div class="tw-flex tw-flex-row tw-justify-between tw-items-end">
            <h2 class="tw-text-xl lg:tw-text-2xl tw-font-bold">
              Gesamt letzte 12 Monate
            </h2>
            <template v-if="$apollo.queries.month_statistics.loading">
              <v-skeleton-loader
                class="tw-w-28 tw-ml-auto"
                height="23"
                type="text" />
            </template>
            <template v-else>
              <Amount :value="totalvolume" class="tw-font-extrabold" />
            </template>
          </div>
          <StatusLine
            :portions="[{ color: '#EDEDED', proportion: '1' }]"
            class="tw-mt-2"
            stroke="2" />
        </div>
        <div class="lg:tw-col-start-2 lg:tw-row-start-3">
          <h2
            class="tw-text-xl lg:tw-text-2xl tw-font-bold tw-text-ci-gray-700">
            Anstehende Abrechnungen
          </h2>
          <div class="tw-relative">
            <FilesDashboard
              :items="processedfiles"
              :loading="$apollo.queries.processedfiles.loading"
              class="tw-pointer-events-none" />
            <router-link :to="{ name: 'billings.pending' }">
              <span class="tw-inset-0 tw-absolute" />
              <span
                class="tw-text-ci-green tw-font-bold tw-mt-5 tw-inline-block">
                Alle anstehenden Abrechnungen
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </template>
  </SanadeusPage>
</template>

<script>
import FilesDashboard from '@/components/Billings/FilesDashboard.vue'
import SanadeusPageHeader from '@/components/SanadeusPage/SanadeusPageHeader.vue'
import BillingsDashboardQuery from '@/gql/queries/DashboardBillings.graphql'
import moment from 'moment'
import { mapGetters } from 'vuex'
import BarChart from '../components/Dashboard/BarChart.vue'
import DoughnutChart from '../components/Dashboard/DoughnutChart.vue'
import MonthPicker from '../components/helper/MonthPicker.vue'
import StatusLine from '../components/helper/StatusLine.vue'
import SanadeusPage from '../components/SanadeusPage/SanadeusPage.vue'

export default {
  components: {
    SanadeusPage,
    SanadeusPageHeader,
    StatusLine,
    BarChart,
    DoughnutChart,
    MonthPicker,
    FilesDashboard,
  },
  data: function () {
    return {
      sentfiles: [],
      processedfiles: [],
      selectedmonth: moment().subtract(1, 'month').toDate(),
      month_statistics: [],
      openpayments: {
        open: 0,
        due: 0,
        overdue: 0,
      },
    }
  },
  computed: {
    ...mapGetters(['selectedNode']),
    totalopen: function () {
      return [
        this.openpayments.open,
        this.openpayments.due,
        this.openpayments.overdue,
      ].reduce((sum, cur) => sum + cur, 0)
    },
    openportions: function () {
      return [
        {
          color: '#82C8E2',
          proportion: this.openpayments.open,
        },
        {
          color: '#EF8305',
          proportion: this.openpayments.due,
        },
        {
          color: '#F82C06',
          proportion: this.openpayments.overdue,
        },
      ]
    },
    doughnutchartdata: function () {
      if (!this.selectedmonth_statistics) {
        return {
          open: 0,
          due: 0,
          overdue: 0,
          paid: 0,
          total: 0,
          numberOfEntries: 0,
        }
      }
      return this.selectedmonth_statistics
    },
    barchartdata: function () {
      return this.month_statistics.map((data) => {
        return {
          value: data.statistics.total,
          month: data.label,
        }
      })
    },
    totalvolume: function () {
      return this.month_statistics.reduce((acc, cur) => {
        acc += cur.statistics.total
        return Math.round(acc * 100) / 100
      }, 0)
    },
  },
  apollo: {
    processedfiles: {
      query: BillingsDashboardQuery,
      variables: function () {
        return {
          node: this.selectedNode,
          state: 'processed',
          limit: 3,
        }
      },
      fetchPolicy: 'network-only',
      update: (data) => data.node_files,
    },
    sentfiles: {
      query: BillingsDashboardQuery,
      variables: function () {
        return {
          node: this.selectedNode,
          state: 'sent',
          processState: 'VALID',
          limit: 3,
        }
      },
      fetchPolicy: 'network-only',
      update: (data) => data.node_files,
    },
    month_statistics: {
      query: require('@/gql/queries/monthStatistics.graphql'),
      variables() {
        return {
          node: this.selectedNode,
          from: moment().subtract(12, 'month').startOf('month').toDate(),
          to: moment().toDate(),
        }
      },
      fetchPolicy: 'network-only',
    },
    selectedmonth_statistics: {
      query: require('@/gql/queries/monthStatistics.graphql'),
      variables() {
        return {
          node: this.selectedNode,
          from: moment(this.selectedmonth).startOf('month').toDate(),
          to: moment(this.selectedmonth).endOf('month').toDate(),
        }
      },
      fetchPolicy: 'network-only',
      update: (data) => data.month_statistics[0],
    },
    openpayments: {
      query: require('@/gql/queries/openpayments.graphql'),
      variables: function () {
        return {
          node: this.selectedNode,
        }
      },
      fetchPolicy: 'network-only',
    },
  },
}
</script>

<style scoped>
.doughnut_legendbox {
  height: 100%;
  margin-left: 30px;
  width: 240px;
}

.legend_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}

.legend_color {
  height: 20px;
  margin-right: 15px;
  width: 20px;
}

.legend_amount {
  font-weight: bold;
  margin-right: 15px;
  width: 100px;
}

.legend_row .legend_amount {
  text-align: right;
}

.legend_label {
  color: #919090;
  font-size: 0.9rem;
}

.bgcolor_open {
  background-color: #82c8e2;
}

.bgcolor_due {
  background-color: #ef8305;
}

.bgcolor_overdue {
  background-color: #f82c06;
}

.bgcolor_paid {
  background-color: #00a82d;
}

.doughnut_counter {
  color: #1f78b4;
  font-size: 48px;
  font-weight: 700;
  height: 102px;
  margin-top: 78px;
  position: absolute;
  text-align: center;
  width: 180px;
}

a {
  color: inherit !important;
  text-decoration: none;
}
</style>
