<template>
  <ul class="invoices tw-divide-y-2 tw-divide-ci-gray-150">
    <InvoiceRowHeader />
    <li v-for="invoice in invoices" :key="invoice._id">
      <InvoiceRow :invoice="invoice" :transaction="transaction"></InvoiceRow>
    </li>
  </ul>
</template>

<script>
import InvoiceRow from './InvoiceRow'
import InvoiceRowHeader from './InvoiceRowHeader'

export default {
  components: {
    InvoiceRow,
    InvoiceRowHeader,
  },
  props: {
    invoices: Array,
    transaction: Object,
  },
  data: function () {
    return {}
  },
  methods: {},
}
</script>

<style>
ul.invoices {
  list-style-type: none;
  padding: 0 !important;
  width: 100%;
}

.invoices_grid {
  display: grid;
  grid-gap: 6px 25px;
  grid-template-columns: 130px 210px 100px 1fr 100px;
}
</style>
